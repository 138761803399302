import React, { memo, useState, useEffect, useRef } from 'react'

import styled from 'styled-components'
import StaticSideNav from 'components/StaticSideNav'
import { CalendarContextProvider } from 'hooks/CalendarContext'
import CalendarWidget from 'modules/dashboard/components/CalendarWidget'
import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'
import SatlyGreeting from '../../public/assets/illustrations/SaltyGreeting.svg'
import { BookProgressChartWidget } from './BookProgressChartWidget'
import WidgetTutoringWeeklySchedule from 'modules/tutoring/components/WidgetTutoringWeeklySchedule'
import { useTranslation } from 'react-i18next'
import StepsForSuccessWidget from 'modules/onboarding/components/StepsForSuccessWidget'
import NextTasks from 'modules/dashboard/tasksWidget/NextTasks'

import PATHS from 'utils/paths'
import {
  StepsForSuccessSkeletonLoader,
  NextTasksSkeletonLoader,
  CalendarSkeletonLoader,
  TutorsSkeletonLoader
} from 'modules/dashboard/components/SkeletonWidgets'
import { SkeletonRect } from 'components/Skeleton/SkeletonLoader'
import AiWidget from 'modules/dashboard/aiWidget/AiWidget'

import {
  getAiTutorEnabledInCourse,
  getCurrentCourse
} from 'modules/courses/ducks/selectors'
import { Course } from 'types/courses'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { RootReducer } from '../types'
const MemoizedCalendar = memo(CalendarWidget)

const NewDashboard = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const studentProfile = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const dashboardSettings = currentCourse.original?.dashboard_settings || '{}'
  const dashboardSettingsObject = JSON.parse(dashboardSettings)

  const aiTutorEnabledInCourse = useSelector((state: RootReducer) =>
    getAiTutorEnabledInCourse(state)
  )
  const isCalendarEnabled = currentCourse.original?.is_calendar_enabled || false
  const isBookProgressWidgetEnabled =
    dashboardSettingsObject?.['books_progress_enabled'] || false
  const isTutorsWidgetEnabled =
    dashboardSettingsObject?.['tutoring_enabled'] || false
  const isStepsForSuccessWidgetEnabled =
    dashboardSettingsObject?.['steps_for_success_enabled'] || false

  const isDashboardAvailable =
    isCalendarEnabled ||
    isBookProgressWidgetEnabled ||
    isTutorsWidgetEnabled ||
    isStepsForSuccessWidgetEnabled ||
    aiTutorEnabledInCourse

  const { name } = studentProfile
  const [_, lastName] = name.split(' ')

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000)
  }, [])

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && !isDashboardAvailable) {
      push(PATHS.exams)
    }
  }, [currentCourse])

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleScroll = () => {
      const scrolled = window.scrollY
      container.style.setProperty('--scroll', `${scrolled * -0.1}px`)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  if (isLoading) {
    return (
      <DashboardContainer>
        <StaticSideNav />
        <MainContent>
          <Header>
            <SkeletonRect width='300px' height='36px' />
          </Header>
          <Content>
            <ContentColumn>
              <StepsForSuccessSkeletonLoader />
              <NextTasksSkeletonLoader />
            </ContentColumn>
            <ContentColumn>
              <CalendarSkeletonLoader />
              <TutorsSkeletonLoader />
            </ContentColumn>
          </Content>
        </MainContent>
      </DashboardContainer>
    )
  }
  return (
    <>
      <DashboardWrapper>
        <DashboardBackground />
        <CalendarContextProvider>
          <Header>
            <h1>{t('dashboard.welcome', { lastName })}</h1>
            <img src={SatlyGreeting} alt='Salty Greeting' />
          </Header>
          <DashboardContainer ref={containerRef}>
            <StaticSideNav />
            <MainContent>
              <Content hasTwoColumns={isCalendarEnabled}>
                <ContentColumn>
                  {isStepsForSuccessWidgetEnabled && <StepsForSuccessWidget />}
                  {isCalendarEnabled && !isTutorsWidgetEnabled && (
                    <MemoizedCalendar />
                  )}
                  {isCalendarEnabled && isTutorsWidgetEnabled && <NextTasks />}
                </ContentColumn>
                <ContentColumn>
                  {isCalendarEnabled && isTutorsWidgetEnabled && (
                    <MemoizedCalendar />
                  )}
                  {isCalendarEnabled && !isTutorsWidgetEnabled && <NextTasks />}
                  {isTutorsWidgetEnabled && (
                    <Tutors>
                      <WidgetTutoringWeeklySchedule />
                    </Tutors>
                  )}
                </ContentColumn>
              </Content>
              {isBookProgressWidgetEnabled && <BookProgressChartWidget />}
              {aiTutorEnabledInCourse && <AiWidget />}
            </MainContent>
          </DashboardContainer>
        </CalendarContextProvider>
      </DashboardWrapper>
    </>
  )
}

export default NewDashboard

const DashboardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background-image: url('/assets/illustrations/dashboard_hexagons.svg');
  background-repeat: no-repeat;
`

const DashboardWrapper = styled.div`
  overflow: visible;
  position: relative;
`

const DashboardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

const MainContent = styled.div`
  flex: 1;
  padding-left: 20px;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding-left: 48px;
  margin-bottom: 10px;

  h1 {
    font-family: 'Arial Black', Arial, sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.main.black};
    max-height: max-content;
    white-space: nowrap;
    max-width: 820px;
    overflow-x: clip;
    overflow-y: visible;
    text-overflow: ellipsis;
    z-index: 1;
  }

  img {
    z-index: 1;
  }
`

const Content = styled.div<{ hasTwoColumns?: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasTwoColumns }) =>
    hasTwoColumns ? 'repeat(2, 1fr)' : '1fr'};
  gap: 20px;
`

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const Tutors = styled.div`
  margin-bottom: 20px;
`
