import { useEffect, useState } from 'react'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/AIChat/utils/events'
import { sendStream, getChatHistory } from 'services/AIChatService'

interface ChatMessage {
  role: 'student' | 'bot'
  contents: string
  score?: number
}

/**
 * Custom hook for managing AI chat functionality with real-time message streaming
 *
 * Flow:
 * 1. Message Initialization:
 *    - User's message is added to chat
 *    - Empty bot message is created immediately
 *    - Stream connection is established
 *
 * 2. Real-time Processing:
 *    - Incoming chunks are processed immediately
 *    - Messages are updated character by character
 *    - Updates are non-blocking for smooth UI
 *
 * 3. Completion:
 *    - Final message content is verified
 *    - Score is extracted and added
 *    - Chat state is cleaned up
 */
export default (chapterId: string) => {
  // State for managing chat messages and UI
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [streamedMessage, setStreamedMessage] = useState('')
  const [cumulatedScores, setCumulatedScores] = useState<number>(0)
  const [cumulatedStudentPromptNumber, setCumulatedStudentPromptNumber] =
    useState<number>(0)
  const [score, setScore] = useState<number | undefined>(undefined)

  // Helper function to safely parse JSON with fallback
  function safeJSONParse(string, fallbackValue: any = {}) {
    try {
      return JSON.parse(string)
    } catch (e) {
      return fallbackValue
    }
  }

  // Cleans formula text by removing escape characters
  const cleanFormula = (text: string): string => {
    return text.replace(/\\{/g, '{').replace(/\\}/g, '}')
  }

  // Extracts message contents and score from stream chunks
  const extractContents = (string: string) => {
    const lines = string.split('\n').filter(Boolean)
    const contents: string[] = []

    for (const line of lines) {
      if (!line.startsWith('data: ')) continue

      try {
        const jsonStr = line.substring('data: '.length)
        const parsed = safeJSONParse(jsonStr)
        if (!parsed) continue

        // Then handle content extraction
        if (parsed.json && Array.isArray(parsed.json)) {
          const actualContent = parsed.json.find(
            item =>
              typeof item === 'string' &&
              !item.includes('"query":') &&
              !item.includes('"follow_up_quiz_question":')
          )
          if (actualContent) {
            contents.push(cleanFormula(actualContent))
          }
          continue
        }
      } catch (e) {
        console.error('Error parsing line:', e)
      }
    }

    return { contents }
  }

  // Extracts final score from complete stream output
  const extractScore = (string: string): number | undefined => {
    try {
      const lines = string.split('\n').filter(Boolean)
      for (const line of lines) {
        if (!line.startsWith('data: ')) continue

        const jsonStr = line.substring('data: '.length)
        const parsed = safeJSONParse(jsonStr)

        // Check in the json array
        if (parsed.json && Array.isArray(parsed.json)) {
          const scoreObj = parsed.json.find(
            item => typeof item === 'object' && 'score_number' in item
          )
          if (scoreObj?.score_number !== undefined) {
            return Number(scoreObj.score_number)
          }
        }

        // Check in intermediateSteps
        if (
          parsed.intermediateSteps?.[0]?.action?.toolInput?.score_number !==
          undefined
        ) {
          return Number(
            parsed.intermediateSteps[0].action.toolInput.score_number
          )
        }
      }
    } catch (e) {
      console.error('Error extracting score:', e)
    }
    return undefined
  }

  // Adds a new message to the chat
  const addMessage = (newMessage: ChatMessage) => {
    setMessages(prevMessages => [...prevMessages, newMessage])
  }

  // Main function for handling message sending and receiving
  const handleSendMessage = async (input: string) => {
    // Reset states for new message
    setStreamedMessage('')
    addMessage({ role: 'student', contents: input })
    setIsLoading(true)

    // Initialize stream connection
    const response = await sendStream(input, chapterId)
    const reader = response.body
      ? response.body.pipeThrough(new TextDecoderStream()).getReader()
      : null

    // Message state tracking
    let accumulatedMessage = ''
    let completeStreamOutput = ''
    const CHUNK_UPDATE_DELAY = 0 // Minimal delay for smooth rendering

    // Helper function for updating bot message with minimal delay
    const updateBotMessage = async (content: string) => {
      await new Promise(resolve => setTimeout(resolve, CHUNK_UPDATE_DELAY))
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages]
        const lastMessage = updatedMessages[updatedMessages.length - 1]
        if (lastMessage?.role === 'bot') {
          updatedMessages[updatedMessages.length - 1] = {
            ...lastMessage,
            contents: content
          }
        } else {
          updatedMessages.push({
            role: 'bot',
            contents: content
          })
        }
        return updatedMessages
      })
    }

    try {
      // Process incoming stream chunks
      while (reader) {
        const { value, done } = await reader.read()
        if (done) break
        if (value) {
          // Accumulate complete stream for final processing
          completeStreamOutput += value
          const { contents: newContents } = extractContents(value)

          if (newContents.length > 0) {
            setIsLoading(false)

            const latestContent = newContents[newContents.length - 1]

            // Update message if new content is longer
            if (latestContent.length > accumulatedMessage.length) {
              // Update character by character for smooth animation
              for (
                let i = accumulatedMessage.length;
                i < latestContent.length;
                i++
              ) {
                const newContent = latestContent.slice(0, i + 1)
                await updateBotMessage(newContent) // Add await here to ensure sequential updates
              }
              accumulatedMessage = latestContent
            }
          }
        }
      }

      // Process final message content
      const { contents: finalContents } = extractContents(completeStreamOutput)
      if (finalContents.length > 0) {
        const finalContent = finalContents[finalContents.length - 1]
        if (finalContent.length > accumulatedMessage.length) {
          accumulatedMessage = finalContent
          await updateBotMessage(accumulatedMessage)
        }
      }

      // Extract and add final score
      const finalScore = extractScore(completeStreamOutput)
      setScore(prevState => (finalScore === undefined ? prevState : finalScore))
      const transformedFinalScore = finalScore || 0
      setCumulatedScores(prevScores => prevScores + transformedFinalScore)

      // Update final message with score
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages]
        const lastMessage = updatedMessages[updatedMessages.length - 1]
        if (lastMessage?.role === 'bot') {
          updatedMessages[updatedMessages.length - 1] = {
            ...lastMessage,
            contents: accumulatedMessage,
            score: finalScore
          }
        }
        return updatedMessages
      })
      setCumulatedStudentPromptNumber(prevNumber => prevNumber + 1)
    } catch (error) {
      // Clean up on error
      console.error('Error reading stream:', error)
      setMessages(prevMessages =>
        prevMessages.filter(msg => msg.role !== 'bot')
      )
    } finally {
      // Reset states and emit completion event
      setIsLoading(false)
      setStreamedMessage('')
      eventEmitter.emit(events.aiChatTutorMessageSent)
    }
  }

  // Clears chat history and resets states
  const handleClearChat = () => {
    getChatHistory({ student_book_chapter_id: chapterId, new_context: true })
    setMessages([])
    setStreamedMessage('')
    setScore(0)
    setCumulatedScores(0)
    setCumulatedStudentPromptNumber(0)
  }

  // Clear chat when chapter changes
  useEffect(() => {
    handleClearChat()
  }, [chapterId])

  return {
    messages,
    handleSendMessage,
    streamedMessage,
    handleClearChat,
    isLoading,
    score,
    cumulatedScores,
    cumulatedStudentPromptNumber
  }
}
