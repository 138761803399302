import React, { useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { propOr, pipe, equals, add } from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import qs from 'qs'
import { useTranslation } from 'react-i18next'

import { Tabs, ResizeIcon, Scroller } from 'examkrackers-components'

import { BOOK_VIEWER_PARAMS } from 'utils/books'

import FlashcardsPreview from 'modules/books/components/FlashcardsPreview'
import NotesPreview from 'modules/books/components/NotesPreview'
import ContentQuestionsPreview from 'modules/books/components/ContentQuestionsPreview'
import FixedImagesPreview from 'modules/books/components/FixedImagesPreview'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { useSelector, useDispatch } from 'react-redux'
import { Course, RootReducer } from 'types'
import {
  getBookContentFlashcardsBySubchapterId,
  getSubchapterById,
  getBookContentImages,
  getBookChapterImages,
  getChapterByOrder,
  getHasFlashcardsAccessibleByOriginalId,
  getBookByOriginalId,
  getAiTutorEnabledByOriginalId
} from 'modules/books/ducks/selectors'
import NotesLoadingState from 'modules/books/components/NotesLoadingState'
import ChapterImagesPreview from './ChapterImagesPreview'
import Padlock from './Padlock'
import { saveRightColumnState } from 'services/BooksService'
import { refreshBookFragmentDataRoutine } from '../ducks/actions'

import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

import { getStudent } from 'modules/auth/ducks/selectors'
import {
  getAiTutorEnabledInCourse,
  getCurrentCourse
} from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
// import AIChat from './AIChat'
import AIChat from 'modules/AIChat/components/AIChat'

export const TABS = {
  images: 'images',
  flashcards: 'flashcards',
  notes: 'notes',
  contentQuestions: 'contentQuestions',
  customTab: 'customTab',
  aiChat: 'aiChat'
}

export const BookRightColumn = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )
  const { location, push } = useHistory()
  const { search, pathname } = location
  const params = useParams()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const chapterOrder: string = propOr('1', 'chapterOrder', params)
  const bookId: string = propOr('', 'bookId', params)
  const partOrder: string = propOr('', 'partOrder', params)

  const hasFlashcardsAccessible = useSelector(state =>
    getHasFlashcardsAccessibleByOriginalId(state, bookId)
  )

  const chapter = useSelector(state =>
    getChapterByOrder(state, Number(chapterOrder))
  )

  const chapterId: string = propOr('', 'id', chapter)

  const customTabTitle = propOr(null, 'image_tab_title')(chapter)

  const hasCustomTab = pipe(
    propOr(null, 'image_tab_title'),
    isNotNilOrEmpty
  )(chapter)

  const isBottomExpanded = pipe(
    propOr('false', BOOK_VIEWER_PARAMS.rightBottomExpand),
    equals('true')
  )(parsedQuery)

  const imageOrder = pipe(
    propOr('0', BOOK_VIEWER_PARAMS.imageSlide),
    Number,
    add(1)
  )(parsedQuery)

  const isTopExpanded = pipe(
    propOr('false', BOOK_VIEWER_PARAMS.rightTopExpand),
    equals('true')
  )(parsedQuery)

  const topActive: string = pipe(propOr('', BOOK_VIEWER_PARAMS.rightTopActive))(
    parsedQuery
  )

  const bottomActive: string = pipe(
    propOr('', BOOK_VIEWER_PARAMS.rightBottomActive)
  )(parsedQuery)

  const subchapterId: string = propOr(
    '',
    BOOK_VIEWER_PARAMS.sectionIdContext
  )(parsedQuery)

  const fixedImages = useSelector((state: RootReducer) =>
    getBookContentImages(state)
  )

  const chapterImages = useSelector((state: RootReducer) =>
    getBookChapterImages(state)
  )

  const flashcards = useSelector((state: RootReducer) =>
    getBookContentFlashcardsBySubchapterId(state, subchapterId)
  )

  const subchapter = useSelector((state: RootReducer) =>
    getSubchapterById(state, subchapterId)
  )

  const subchapterNumber = `${chapterOrder}.${propOr('', 'order', subchapter)}`

  const book = useSelector(state => getBookByOriginalId(state, bookId))
  const previewState = propOr('', 'preview_state', book)
  const rightColumnLocked = isNotNilOrEmpty(previewState)
  const studentBookId = propOr('', 'id', book)
  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector((state: RootReducer) =>
    getCurrentCourse(state)
  )
  const aiTutorEnabledInCourse = useSelector((state: RootReducer) =>
    getAiTutorEnabledInCourse(state)
  )
  const aiTutorEnabledInBook = useSelector((state: RootReducer) =>
    getAiTutorEnabledByOriginalId(state, bookId)
  )

  const isAiTutorDisabled = !aiTutorEnabledInCourse || !aiTutorEnabledInBook

  const fetchTotalPartData = React.useCallback(
    (bookId: string, chapterOrder: string, partOrder: string) =>
      dispatch(
        refreshBookFragmentDataRoutine({
          params: { bookId, chapterOrder, partOrder },
          fragmentName: 'book'
        })
      ),
    [dispatch]
  )

  const handleSuccess = () => {
    fetchTotalPartData(bookId, chapterOrder, partOrder)
  }

  const handleError = () => {
    showToast({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    })
  }

  const unlockPadlock = () => {
    rightColumnLocked &&
      saveRightColumnState({ studentBookId, preview_state: '' })
        .then(handleSuccess)
        .catch(handleError)
  }

  const toggleBottomExpand = () => {
    const newBottomValue = !isBottomExpanded
    const newTopValue = newBottomValue ? false : isTopExpanded

    const newQuery = qs.stringify({
      ...parsedQuery,
      [BOOK_VIEWER_PARAMS.rightBottomExpand]: newBottomValue,
      [BOOK_VIEWER_PARAMS.rightTopExpand]: newTopValue
    })
    push(`${pathname}?${newQuery}`)
    unlockPadlock()
  }
  const toggleTopExpand = () => {
    const newTopValue = !isTopExpanded
    const newBottomValue = newTopValue ? false : isBottomExpanded

    const newQuery = qs.stringify({
      ...parsedQuery,
      [BOOK_VIEWER_PARAMS.rightBottomExpand]: newBottomValue,
      [BOOK_VIEWER_PARAMS.rightTopExpand]: newTopValue
    })
    push(`${pathname}?${newQuery}`)
    unlockPadlock()
  }

  const customTabLabel = hasCustomTab
    ? [
        {
          label: `${customTabTitle}: ${chapterImages.length}`,
          value: TABS.customTab
        }
      ]
    : []

  const customTabContent = hasCustomTab
    ? [
        {
          value: TABS.customTab,
          content: <ChapterImagesPreview />
        }
      ]
    : []

  const flashcardContent = hasFlashcardsAccessible
    ? [
        {
          value: TABS.flashcards,
          content: <FlashcardsPreview />
        }
      ]
    : []

  const aiTutorContent = isAiTutorDisabled
    ? []
    : [
        {
          value: TABS.aiChat,
          content: <AIChat isEvaluation={false} chatContext={chapterId} />
        }
      ]

  const aiTutorTab = isAiTutorDisabled
    ? []
    : [{ label: `${t('bookViewer.aiChat.label')}`, value: TABS.aiChat }]

  const flashcardTab = hasFlashcardsAccessible
    ? [
        {
          label: `${t('bookViewer.flashcards.label')}: ${flashcards.length}`,
          value: TABS.flashcards
        }
      ]
    : []

  const topTabs = [
    {
      label: `${t('bookViewer.images.label')}: ${
        fixedImages.length > 0 ? imageOrder + '/' : ''
      }${fixedImages.length}`,
      value: TABS.images
    },
    ...flashcardTab,
    ...customTabLabel
  ]

  const topContents = [
    {
      value: TABS.images,
      content: <FixedImagesPreview rightColumnLocked={rightColumnLocked} />
    },
    ...flashcardContent,
    ...customTabContent
  ]

  const bottomTabs = [
    {
      label: `${t('bookViewer.contentQuestions.label')}`,
      value: TABS.contentQuestions
    },
    ...aiTutorTab,
    {
      label: `${t('bookViewer.notes.label')}`,
      value: TABS.notes
    }
  ]

  const bottomContents = [
    {
      value: TABS.notes,
      content: (
        <RightBottomNoScroll>
          <NotesPreview />
        </RightBottomNoScroll>
      )
    },
    {
      value: TABS.contentQuestions,
      content: (
        <RightBottomScroll
          id='question-container'
          data-testid='ContentQuestionsPreview-container'
        >
          <ContentQuestionsPreview subchapterNumber={subchapterNumber} />
        </RightBottomScroll>
      )
    },
    ...aiTutorContent
  ]

  const handleTabsChange = param => value => {
    const shrinkBottomIfTopIsActivated =
      param === BOOK_VIEWER_PARAMS.rightTopActive
        ? {
            [BOOK_VIEWER_PARAMS.rightBottomExpand]: false
          }
        : {}

    const newQuery = qs.stringify({
      ...parsedQuery,
      [param]: value,
      ...shrinkBottomIfTopIsActivated
    })
    push(`${pathname}?${newQuery}`)
    unlockPadlock()
  }

  useEffect(() => {
    if (isNilOrEmpty(topActive)) {
      handleTabsChange(BOOK_VIEWER_PARAMS.rightTopActive)(TABS.images)
    }

    if (isNilOrEmpty(bottomActive)) {
      handleTabsChange(BOOK_VIEWER_PARAMS.rightBottomActive)(
        TABS.contentQuestions
      )
    }
  }, [search])

  useEffect(() => {
    if (isNotNilOrEmpty(topActive)) {
      if (topActive === TABS.flashcards && isNotNilOrEmpty(flashcards)) {
        AnalyticsService(user).logEvent(
          ANALYTICS_EVENTS.startStudyingFlashcardsFromABook,
          {
            'Course type': currentCourse.type || false,
            'Course name': currentCourse.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Flashcard Book tag': book?.tag || false,
            'Flashcard box tag': `${book?.tag}_${chapter.order}`
          }
        )
      }
    }
  }, [topActive])

  const showTopResizer: boolean = useMemo(() => {
    switch (topActive) {
      case TABS.images:
        return isNotNilOrEmpty(fixedImages)
      case TABS.customTab:
        return isNotNilOrEmpty(chapterImages)
      case TABS.flashcards:
        return isNotNilOrEmpty(flashcards)
      default:
        return false
    }
  }, [topActive, fixedImages, flashcards, search, chapterImages])

  return (
    <RightContainer data-testid='BookRightColumn'>
      <Padlock />
      <RightTop
        data-testid='RightColumnTop'
        topExpanded={isTopExpanded}
        bottomExpanded={isBottomExpanded}
      >
        {showTopResizer && !isBottomExpanded && (
          <Resizer
            data-testid='TopResizer'
            className='resizer'
            onClick={toggleTopExpand}
          >
            <ResizeIcon />
          </Resizer>
        )}
        <Tabs
          data-testid='TopTabs'
          onChange={handleTabsChange(BOOK_VIEWER_PARAMS.rightTopActive)}
          activeTab={topActive}
          position='topLeft'
          tabs={topTabs}
          tabContents={topContents}
        />
      </RightTop>
      <RightBottom
        data-testid='RightColumnBottom'
        bottomExpanded={isBottomExpanded}
        topExpanded={isTopExpanded}
      >
        <NotesLoadingState />
        <Resizer data-testid='BottomResizer' onClick={toggleBottomExpand}>
          <ResizeIcon />
        </Resizer>
        <Tabs
          data-testid='BottomTabs'
          onChange={handleTabsChange(BOOK_VIEWER_PARAMS.rightBottomActive)}
          activeTab={bottomActive}
          position='topRight'
          tabs={bottomTabs}
          tabContents={bottomContents}
        />
      </RightBottom>
    </RightContainer>
  )
}

export default BookRightColumn

const RightTop = styled.div`
  min-height: ${({ topExpanded, bottomExpanded }) => {
    switch (true) {
      case bottomExpanded:
        return '0px'
      case topExpanded:
        return '572px'
      default:
        return '400px'
    }
  }};
  max-height: ${({ topExpanded, bottomExpanded }) => {
    switch (true) {
      case bottomExpanded:
        return '0px'
      case topExpanded:
        return '572px'
      default:
        return '400px'
    }
  }};
  width: ${({ topExpanded }) => (topExpanded ? 'calc(100% + 215px)' : '100%')};
  transform: translateX(${({ topExpanded }) => (topExpanded ? '-215px' : '0')});
  flex: none;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  // this z-index prevents the scrollbar from overlapping over the expanded right elements in safari
  z-index: ${({ topExpanded, theme }) => topExpanded && theme.zIndex.drawer};

  .resizer {
    opacity: 0;
    transition: opacity 300ms
      ${({ theme }) => theme.transitions.easing.easeInOut};
  }

  &:hover .resizer {
    opacity: 1;
  }
  transition: all 300ms ease-out;
`

const RightBottom = styled.div`
  margin-top: ${({ bottomExpanded, topExpanded }) =>
    bottomExpanded ? '0' : topExpanded ? '-56px' : '29px'};
  height: ${({ bottomExpanded }) =>
    bottomExpanded ? '100%' : 'calc(100% - 400px - 24px)'};
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  transition: all 300ms ease-out;
`

const RightContainer = styled.div`
  min-width: 500px;
  max-width: 500px;
  width: 39.84%;
  height: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

const RightBottomScroll = styled(Scroller)`
  width: 100%;
  height: 100%;
`

const RightBottomNoScroll = styled.div`
  width: 100%;
  height: 100%;
`
/*
// Resizer:
// from:
// z-index: ${({ theme }) =>theme.zIndex.drawer + 90};
// to: 1
// According to the EK-1068 assignment, the images are pushing out the notes and CQ.
// That is why I changed margin to negative. However, resizes has z-index: 1290.
// Even using if, changing the value itself takes a while and it looks unaesthetic.
// That is why I changed value to 1. For me it works fine if you have any problems please contact me.
// Sieradz
*/
const Resizer = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  cursor: pointer;
  z-index: 1;
  font-size: 12px;
  transition: all 200ms ${({ theme }) => theme.transitions.easing.easeInOut};

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary500};
  }
`
