import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  EmptyType,
  isCommonTask,
  isEmptyTask,
  StudentTask,
  StudentTaskContent,
  Task
} from 'types/dashboard'
import { updateCalendarEvent } from 'services/CalendarService'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { useDispatch } from 'react-redux'
import { CalendarContext } from 'hooks/CalendarContext'
import TasksWidgetService, {
  TaskStatus,
  TaskStatusType
} from './TasksWidgetService'
import { toggleIsCompleted } from 'services/TaskService'
import TaskCard from './TaskCard'
import { take } from 'ramda'
import { calendarEventType } from 'utils/calendar'
import { useHistory } from 'react-router-dom'
import EmptyStateCard from './EmptyStateCard'
import eventEmitter from 'providers/eventEmitter'
import events from '../../dashboard/utils/events'

interface TasksWidgetProps {
  tasks: (Task | StudentTask)[]
  onSeeCalendarClick: () => void
}

const TasksWidget: React.FC<TasksWidgetProps> = ({
  tasks,
  onSeeCalendarClick
}: TasksWidgetProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { push } = history
  const { updateTask } = useContext(CalendarContext)

  const [visibleTasks, setVisibleTasks] =
    useState<(Task | StudentTask | EmptyType)[]>(tasks)

  useEffect(() => {
    setVisibleTasks(tasks)
  }, [tasks])
  const currentTask = visibleTasks[0]
  const allTasksAreEmpty = visibleTasks.every(isEmptyTask)
  const tasksToShow = allTasksAreEmpty
    ? take(2, visibleTasks)
    : take(3, visibleTasks)

  const redirectToSelectItem = (task: Task) => {
    const isLiveClass =
      task.type === calendarEventType.liveClass ||
      task.type === calendarEventType.customLiveClass ||
      task.type === calendarEventType.customEndDateEvent
    if (isLiveClass) {
      window.open(task.action_uri, '_blank')
    } else {
      push(task.action_uri)
    }
  }

  const handleUpdateEvent = async (
    task: Task | StudentTask | EmptyType,
    updateStatus: TaskStatusType = TaskStatus.COMPLETE
  ) => {
    if (isEmptyTask(task)) {
      return
    }

    try {
      const response = await updateCalendarEvent({
        id: task.id,
        status: updateStatus
      })

      const {
        status,
        data: { status: taskStatus }
      } = response

      if ([200, 201].includes(status)) {
        const title = isCommonTask(task) ? task.title : task.task.name

        updateTask(task.id, { status: taskStatus })

        // Emit event for calendar sync
        eventEmitter.emit(events.taskStatusChanged, {
          taskId: task.id,
          status: taskStatus
        })

        dispatch(
          showToastRoutine({
            key: `${title}${t(
              'dashboard.nextTasks.statusHasChangedToComplete'
            )}`,
            options: { taskTitle: title },
            severity: 'success'
          })
        )

        setVisibleTasks(prevTasks => {
          const newTasks = prevTasks.filter(item => item.id !== task.id)

          if (visibleTasks.length <= 3) {
            return TasksWidgetService.addEmptyTasks(newTasks)
          }

          return newTasks
        })
      }
    } catch (error) {
      console.error('error', error)
      dispatch(
        showToastRoutine({
          key: 'errorUpdatingTask',
          severity: 'error'
        })
      )
    }
  }

  const handleStudentTaskUpdateEvent = async (task: StudentTask) => {
    try {
      const response = await toggleIsCompleted(task.id)

      const { status } = response

      if ([200, 201].includes(status)) {
        const title = isCommonTask(task) ? task.title : task.task.name

        // Emit event for calendar sync
        eventEmitter.emit(events.taskStatusChanged, {
          taskId: task.id,
          status: 'completed'
        })

        dispatch(
          showToastRoutine({
            key: `${title}${t('dashboard.nextTasks.taskSkipped')}`,
            options: { taskTitle: title },
            severity: 'success'
          })
        )

        setVisibleTasks(prevTasks => {
          const newTasks = prevTasks.filter(item => item.id !== task.id)

          if (visibleTasks.length <= 3) {
            return TasksWidgetService.addEmptyTasks(newTasks)
          }

          return newTasks
        })
      }
    } catch (error) {
      console.error('error', error)
      dispatch(
        showToastRoutine({
          key: 'errorUpdatingTask',
          severity: 'error'
        })
      )
    }
  }

  const parseTaskContent = (
    content: string | StudentTaskContent
  ): StudentTaskContent => {
    if (typeof content === 'string') {
      try {
        return JSON.parse(content) as StudentTaskContent
      } catch (e) {
        console.error('Error parsing task content:', e)
        return { videoUrl: '', description: '' } as StudentTaskContent
      }
    }
    return content
  }

  return (
    <div>
      <TitleContainer>
        <Title>{t('dashboard.nextTasks.currentTask')}</Title>
      </TitleContainer>

      {isEmptyTask(currentTask) ? (
        <EmptyStateCard
          isCurrentTask
          visibleTasks={visibleTasks}
          onSeeCalendarClick={onSeeCalendarClick}
        />
      ) : (
        <TaskCard
          key={currentTask.id}
          title={
            isCommonTask(currentTask)
              ? currentTask.title
              : currentTask.task.name
          }
          id={currentTask.id}
          status={
            isCommonTask(currentTask)
              ? currentTask.status === TaskStatus.COMPLETE
              : currentTask.is_completed
          }
          type={
            isCommonTask(currentTask)
              ? currentTask.type
              : currentTask.task.order.toString()
          }
          handleUpdateEvent={() =>
            isCommonTask(currentTask)
              ? handleUpdateEvent(currentTask)
              : handleStudentTaskUpdateEvent(currentTask)
          }
          isCurrentTask
          isCommonTask={isCommonTask(currentTask)}
          content={
            !isCommonTask(currentTask)
              ? parseTaskContent(currentTask.task.content)
              : undefined
          }
          handleRedirectFromActionUri={() =>
            isCommonTask(currentTask) ? redirectToSelectItem(currentTask) : null
          }
          duration={currentTask.duration && currentTask.duration}
          onSeeCalendarClick={onSeeCalendarClick}
          isReviewEvent={
            isCommonTask(currentTask)
              ? TasksWidgetService.isReviewEvent(currentTask.type)
              : false
          }
          isLiveClassAvailable={
            isCommonTask(currentTask) &&
            TasksWidgetService.isLiveClass(currentTask.type)
              ? // @ts-ignore
                TasksWidgetService.checkEventTiming(currentTask)
              : false
          }
          studentExamId={
            isCommonTask(currentTask) ? currentTask.student_exam_id : null
          }
          studentExamIds={
            isCommonTask(currentTask) ? currentTask.student_exam_ids : null
          }
          studentItemId={
            isCommonTask(currentTask) ? currentTask.student_item_id : null
          }
          student_exam_status={
            isCommonTask(currentTask) ? currentTask.student_exam_status : null
          }
          action_uri={isCommonTask(currentTask) ? currentTask.action_uri : ''}
        />
      )}

      <TitleContainer>
        <Title>{t('dashboard.nextTasks.upcomingTasks')}</Title>
        <SeeCalendarButton onClick={onSeeCalendarClick}>
          {t('dashboard.nextTasks.seeCalendar')}
        </SeeCalendarButton>
      </TitleContainer>

      {tasksToShow.map((task, index) => {
        if (isEmptyTask(task)) {
          return (
            <EmptyStateCard
              isCurrentTask={false}
              key={`empty-${index}`}
              index={index}
              visibleTasks={visibleTasks}
              onSeeCalendarClick={onSeeCalendarClick}
            />
          )
        }

        const isFirstUpcomingTask = task.id === visibleTasks[1].id

        if (task.id === currentTask.id) {
          return null
        }

        if (isCommonTask(task)) {
          return (
            <TaskCard
              title={task.title}
              id={task.id}
              duration={task.duration}
              type={task.type}
              status={task.status === TaskStatus.COMPLETE}
              handleUpdateEvent={() => handleUpdateEvent(task)}
              isFirstUpcomingTask={isFirstUpcomingTask}
              isCommonTask={isCommonTask(task)}
              key={task.id}
              handleRedirectFromActionUri={() => redirectToSelectItem(task)}
              onSeeCalendarClick={onSeeCalendarClick}
              isReviewEvent={TasksWidgetService.isReviewEvent(task.type)}
              student_exam_status={task.student_exam_status}
              action_uri={task.action_uri}
              isLiveClassAvailable={
                TasksWidgetService.isLiveClass(task.type)
                  ? TasksWidgetService.checkEventTiming(task)
                  : false
              }
              studentExamIds={isCommonTask(task) ? task.student_exam_ids : null}
              studentItemId={isCommonTask(task) ? task.student_item_id : null}
            />
          )
        }

        return (
          <TaskCard
            title={task.task.name}
            id={task.id}
            status={task.is_completed}
            handleUpdateEvent={() => handleStudentTaskUpdateEvent(task)}
            isFirstUpcomingTask={isFirstUpcomingTask}
            isCommonTask={isCommonTask(task)}
            key={task.id}
            content={parseTaskContent(task.task.content)}
            onSeeCalendarClick={onSeeCalendarClick}
            isReviewEvent={false}
            type={task.task.order.toString()}
          />
        )
      })}
    </div>
  )
}

export default TasksWidget
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h3`
  font-weight: 900;
  font-family: 'Arial Black', Arial, sans-serif;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.main.black};
  margin-bottom: 8px;
`

const SeeCalendarButton = styled.div`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.main.text};
  cursor: pointer;
  font-size: 14px;
  line-height: 25px;
`
