import React from 'react'
import styled from 'styled-components'

import AIChatMessageWrapper from './AIChatMessageWrapper'

const AIChatMessageStudent: React.FC<{
  message: { role: string; contents: string }
}> = ({ message }) => {
  return (
    <AIChatMessageStudentContainer>
      <AIChatMessageWrapper message={message.contents} />
    </AIChatMessageStudentContainer>
  )
}

export default AIChatMessageStudent

const AIChatMessageStudentContainer = styled.div`
  display: inline-flex;
  flex: none;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 16px;
  width: 242px;
  border-radius: 25px;
  align-self: flex-end;
  background-color: ${({ theme }) =>
    theme.colors.AIChat.message.student.background};
`
