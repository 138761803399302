import styled, { keyframes } from 'styled-components'

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const SkeletonBase = styled.div`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 1000px 100%;
  animation: ${fadeIn} 0.3s ease-in, ${shimmer} 2s infinite linear,
    ${pulse} 1.5s ease-in-out 0.5s infinite;
  border-radius: 4px;
`

export const SkeletonRect = styled(SkeletonBase)<{
  width?: string
  height?: string
}>`
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '20px' }) => height};
`

export const SkeletonCircle = styled(SkeletonBase)<{
  size?: string
}>`
  width: ${({ size = '40px' }) => size};
  height: ${({ size = '40px' }) => size};
  border-radius: 50%;
`
