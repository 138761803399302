import { TaskStatus } from 'modules/dashboard/tasksWidget/TasksWidgetService'

export interface FetchStudyTimeDataPayload {
  id: string
}

export type EmptyType = {
  id: number
  status: typeof TaskStatus.EMPTY
}

export interface Task {
  id: string
  title: string
  type: string
  status: string
  event_date: string

  duration: number
  order: number
  action_uri: string
  is_manual: boolean
  event_colour: string
  description: string | null
  student_item_id: string
  student_exam_id: string | null
  student_exam_ids: string | null
  student_exam_status: string | null
  from_manual_setup: boolean
  class_time: string | null
  class_time_end: string | null
  is_locked_in_free_trial: boolean
  original_exam_id: string | null
  parent_event_id: string | null
  examByItem: any | null
  exam: any | null
}

export interface StudentTask {
  id: string
  student_id: string
  task_id: string
  is_completed: boolean
  completed_at: string | null
  created_at: string
  duration?: number
  task: {
    id: string
    name: string
    content: {
      videoUrl: string
      description: string
    }
    order: number
  }
  type?: string
}

export const isCommonTask = (task: Task | StudentTask): task is Task => {
  return (task as Task).type !== undefined
}

export const isEmptyTask = (
  task: Task | StudentTask | EmptyType
): task is EmptyType => {
  return (task as EmptyType).status === TaskStatus.EMPTY
}

export const keyMapping = {
  firstName: 'first_name',
  lastName: 'last_name',
  age: 'years_old',
  city: 'location'
}

export type StudentTaskContent = {
  videoUrl: string
  description: string
}

interface BarPropsColor {
  color:
    | 'purple'
    | 'red'
    | 'blue'
    | 'green'
    | 'orange'
    | 'brown'
    | 'mathPurple'
    | 'aquamarine'
    | 'turquoise'
    | 'yellow'
    | 'grey'
    | 'terraCotta'
    | 'gold'
    | 'tangerine'
    | 'guacamole'
    | 'ultramarine'
    | 'grape'
    | 'moss'
    | 'slate'
}

export interface ChapterDetails {
  id: string
  order: number
  title: string
  average_score: string
  student_book_id: string
  tag: string
  tag_colour: BarPropsColor
  book_title: string
  original_book_id: string
  last_chapter: string
  last_part: string
  last_student_book_subchapter_id_seen: string
}

export interface ChapterPerformance {
  all: number
  strongest: ChapterDetails
  weakest: ChapterDetails
}
