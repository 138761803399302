import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SingleCard from 'modules/dashboard/aiWidget/SingleCard'
import DefaultCard from 'modules/dashboard/aiWidget/DefaultCard'
import { getEvaluation } from 'services/EvaluationService'
import { ChapterPerformance } from 'types/dashboard'
import paths from 'utils/paths'
import { useHistory } from 'react-router-dom'
import AiWidgetEmptyState from 'modules/dashboard/aiWidget/AiWidgetEmptyState'
import { isNotNilOrEmpty } from 'utils/ramda'
const AiWidget = (): JSX.Element => {
  const [evaluationScores, setEvaluationScores] = useState<ChapterPerformance>()
  const { t } = useTranslation()
  const { push } = useHistory()
  const getEvaluationScores = async () => {
    try {
      const res = await getEvaluation()
      setEvaluationScores(res.data)
    } catch (error) {
      console.error('Error fetching evaluation scores:', error)
    }
  }

  const redirectToAiEvaluation = () => {
    push(paths.aiEvaluation)
  }

  useEffect(() => {
    getEvaluationScores()
  }, [])

  const isWeakestOrStrongest =
    isNotNilOrEmpty(evaluationScores?.weakest) ||
    isNotNilOrEmpty(evaluationScores?.strongest)

  if (!isWeakestOrStrongest) {
    return (
      <AiWidgetEmptyState redirectToAiEvaluation={redirectToAiEvaluation} />
    )
  }

  return (
    <>
      <MainContainer>
        <TopRow>
          <WidgetTitle>{t('dashboard.aiWidget.title')}</WidgetTitle>
          <TopButton onClick={redirectToAiEvaluation}>
            {t('dashboard.aiWidget.aiEvaluation')}
          </TopButton>
        </TopRow>
        <CardsContainer>
          <SingleCard isAll all={evaluationScores?.all} />
          <SingleCard
            isStrongest
            evaluationScores={evaluationScores?.strongest}
            redirectToAiEvaluation={redirectToAiEvaluation}
          />
          <SingleCard
            evaluationScores={evaluationScores?.weakest}
            redirectToAiEvaluation={redirectToAiEvaluation}
          />
          <DefaultCard redirectToAiEvaluation={redirectToAiEvaluation} />
        </CardsContainer>
      </MainContainer>
    </>
  )
}

export default AiWidget

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 18px; */
`

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const WidgetTitle = styled.div`
  font-family: 'Arial Black';
  font-size: 18px;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.main.black};
`

const TopButton = styled.div`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.main.text};
  cursor: pointer;
  font-size: 14px;
`
