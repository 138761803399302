import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as R from 'ramda'
import { propOr } from 'ramda'
import qs from 'qs'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/AIChat/utils/events'

import { AI_EVALUATION_PARAMS } from 'utils/aiEvaluation'
import { fetchEvaluationInfo } from 'services/AIEvaluationService'
import AIEvaluationAll from 'modules/AIChat/components/AIEvaluationAll'
import AIEvaluationEmptyState from './AIEvaluationEmptyState'
import AIEvaluationGroupBox from 'modules/AIChat/components/AIEvaluationGroupBox'
import AIEvaluationProgressBar from 'modules/AIChat/components/AIEvaluationProgressBar'
import AIEvaluationTooltip from './AIEvaluationTooltip'

export const AIEvaluation = (): JSX.Element => {
  const [evaluationData, setEvaluationData] = React.useState<any>([])
  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const collapsed = propOr('false', AI_EVALUATION_PARAMS.collapse)(parsedQuery)

  const isCollapsed = collapsed === 'true'

  const isEvaluationEmpty = propOr(0, 'all')(evaluationData) === 0

  const handleFetchEvaluationInfo = () => {
    fetchEvaluationInfo()
      .then(response => {
        setEvaluationData(response.data)
      })
      .catch(error => {
        console.error('Error fetching evaluation data:', error)
      })
  }

  React.useEffect(() => {
    handleFetchEvaluationInfo()
    eventEmitter.on(events.aiChatTutorMessageSent, handleFetchEvaluationInfo)

    return () => {
      eventEmitter.off(events.aiChatTutorMessageSent, handleFetchEvaluationInfo)
    }
  }, [])

  const groupByBookId = R.groupBy(
    (item: {
      student_book_id: string
      tag: string
      tag_colour: string
      order: number
      average_score: number
      title: string
      book_title: string
    }) => item.student_book_id
  )

  const transformedData = R.pipe(
    // @ts-ignore
    groupByBookId,
    R.map(chapters => ({
      bookId: chapters[0].student_book_id,
      tag: chapters[0].tag,
      tag_colour: chapters[0].tag_colour,
      chapters: R.sortBy(R.prop('order'), chapters)
    })),
    R.values
    // @ts-ignore
  )(evaluationData?.chapters || [])

  function sortByTags(data, tagOrder) {
    const tagOrderMap = tagOrder.reduce((acc, tag, index) => {
      acc[tag] = index
      return acc
    }, {})

    return data.sort((a, b) => {
      const indexA =
        tagOrderMap[a.tag] !== undefined ? tagOrderMap[a.tag] : Infinity
      const indexB =
        tagOrderMap[b.tag] !== undefined ? tagOrderMap[b.tag] : Infinity
      return indexA - indexB
    })
  }

  const tagOrder = ['CHE', 'BIO', 'PSY', 'ORG', 'LAB', 'PHY']
  const sortedData = sortByTags(transformedData, tagOrder)

  const renderCharts = sortedData.map(book => {
    return (
      <AIEvaluationGroupBox key={book.bookId}>
        <BookTitle>{book.tag}</BookTitle>
        {book.chapters.map(chapter => {
          return (
            <AIEvaluationTooltip
              key={chapter.id}
              chapter={chapter}
              chapterId={chapter.id}
            >
              <AIEvaluationProgressBar
                color={chapter.tag_colour}
                percent={chapter.average_score}
                title={`${chapter.tag} ${chapter.order}`}
                subtitle={isCollapsed ? chapter.title : null}
              />
            </AIEvaluationTooltip>
          )
        })}
      </AIEvaluationGroupBox>
    )
  })

  return (
    <Container isCollapsed={isCollapsed}>
      {isEvaluationEmpty ? (
        <AIEvaluationEmptyState />
      ) : (
        <>
          <AIEvaluationGroupBox>
            <AIEvaluationAll percent={evaluationData?.all || 0} />
          </AIEvaluationGroupBox>
          {renderCharts}
        </>
      )}
    </Container>
  )
}

export default AIEvaluation

const BookTitle = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  font-family: Arial Black;
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
`

const Container = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: ${({ isCollapsed }) => (isCollapsed ? '100%' : 'calc(100% - 508px)')};
  transition: all 300ms ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;

  /* first row */

  & > #ai-evaluation-group-box:first-child {
    flex: 0 0 40%;
  }

  & > #ai-evaluation-group-box:nth-child(2) {
    flex: 0 0 calc(60% - 8px);
  }

  /* second row */

  & > #ai-evaluation-group-box:nth-child(3) {
    flex: 0 0 100%;
  }

  /* third row */

  & > #ai-evaluation-group-box:nth-child(4) {
    flex: 0 0 calc(70% - 8px);
  }

  & > #ai-evaluation-group-box:nth-child(5) {
    flex: 0 0 calc(30%);
  }

  /* fourth row */

  & > #ai-evaluation-group-box:nth-child(6) {
    flex: 0 0 calc(35% - 8px);
  }

  & > #ai-evaluation-group-box:nth-child(7) {
    flex: 0 0 calc(65%);
  }

  /* All other elements */
  & > #ai-evaluation-group-box:nth-child(n + 8) {
    flex: 0 0 100%;
  }
`
