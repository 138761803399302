import * as React from 'react'
import styled from 'styled-components'
import TutoringWeeklyScheduleTutor from 'modules/tutoring/components/TutoringWeeklyScheduleTutor'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { isNilOrEmpty } from '../../../utils/ramda'
import TutoringWeeklyScheduleDayEmptyState from './TutoringWeeklyScheduleDayEmptyState'
import { TTutorClass } from 'types'

type Props = {
  weeklyScheduleDay: {
    date: string
    classes: TTutorClass[]
  }
}

export const TutoringWeeklyScheduleDay = (props: Props) => {
  const dayTutorClasses: TTutorClass[] = props.weeklyScheduleDay.classes

  const TutoringScheduleList = dayTutorClasses
    .sort((a, b) => {
      return a.class_time.localeCompare(b.class_time)
    })
    .map(tutorClass => (
      <TutoringWeeklyScheduleTutor
        tutorClass={tutorClass}
        key={tutorClass.id}
      />
    ))

  return (
    <Container>
      <Header>
        {formatDate(props.weeklyScheduleDay.date, DATE_FORMATS.describedDay)}
      </Header>
      <Classes>
        {isNilOrEmpty(dayTutorClasses) ? (
          <TutoringWeeklyScheduleDayEmptyState />
        ) : (
          TutoringScheduleList
        )}
      </Classes>
    </Container>
  )
}

export default TutoringWeeklyScheduleDay

const Header = styled.div`
  font-size: 18px;
  font-weight: 900;
  line-height: 25.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-family: 'Arial Black';
`

const Container = styled.div``

const Classes = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
