import APIService from 'services/APIService'

const api = APIService()

export const fetchTutoringDays = (id: string) =>
  api.get(`/group-tutoring-days/course/${id}`)

export const fetchUpcoming7DayTutoring = () =>
  api.get(`/group-tutoring-days/upcoming`)

export const fetchUpcomingTutoring = () =>
  api.get(`/group-tutoring-days/future-classes`)
