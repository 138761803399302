import * as Reselect from 'reselect'
import * as R from 'ramda'
import {
  SETTINGS_NAMESPACE,
  COURSE_SETTINGS,
  SALTY_BUCKS_SETTINGS
} from 'utils/settings'

export const selectSettings = (state: any) => state.settings

export const selectAutoPauseDelay = Reselect.createSelector(
  selectSettings,
  R.pipe(
    R.propOr([], SETTINGS_NAMESPACE.courses),
    R.find(R.propEq('name', COURSE_SETTINGS.autoPauseDelay)),
    R.propOr('0', 'value'),
    Number
  )
)

export const selectAIChatPromptCost = Reselect.createSelector(
  selectSettings,
  R.pipe(
    R.propOr([], 'saltyBucks'),
    R.find(R.propEq('name', SALTY_BUCKS_SETTINGS.aiChatPromptCost)),
    R.propOr('0', 'value'),
    Number
  )
)
