import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import qs from 'qs'
import styled from 'styled-components'

import { AI_EVALUATION_PARAMS } from 'utils/aiEvaluation'

export const AIEvaluation = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const collapsed = propOr('false', AI_EVALUATION_PARAMS.collapse)(parsedQuery)

  const isCollapsed = collapsed === 'true'

  return (
    <Container isCollapsed={isCollapsed}>
      <ContainerInner>
        <ContentContainer>
          <ContentHeader>{t('aiEvaluation.titleTooltip.header')}</ContentHeader>
          <ContentText>
            {t('aiEvaluation.titleTooltip.description')}
          </ContentText>
          <ContentTextOl>
            {t('aiEvaluation.titleTooltip.howItWorks.description')}
          </ContentTextOl>
          <ContentList>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point1')}
            </ContentListItem>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point2')}
            </ContentListItem>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point3')}
            </ContentListItem>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point4')}
            </ContentListItem>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point5')}
            </ContentListItem>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point6')}
            </ContentListItem>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point7')}
            </ContentListItem>
            <ContentListItem>
              {t('aiEvaluation.titleTooltip.howItWorks.point8')}
            </ContentListItem>
          </ContentList>
        </ContentContainer>
      </ContainerInner>
    </Container>
  )
}

export default AIEvaluation

const Container = styled.div<{ isCollapsed: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.backgrounds.main};
  border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
  box-shadow: ${props => props.theme.shadows.darkShadow};
  height: 100%;
  padding: 8px;
  width: 100%;
  flex: 1;
`

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
  background: ${props => props.theme.colors.backgrounds.app};
  height: 100%;
  padding: 16px;
  width: 100%;
`

const ContentContainer = styled.div`
  padding: 16px 0;
  max-width: 436px;
`

const ContentHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

const ContentText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 16px 0;
`

const ContentTextOl = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 8px;
`

const ContentList = styled.ol`
  padding: 0 0 0 18px;
  margin: 0;
`

const ContentListItem = styled.li`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0 0 8px 0;
  margin-left: 0;
`
