import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as R from 'ramda'

import {
  getVideosList,
  getVideosPagination,
  selectBookVideosState,
  selectVideosState
} from 'modules/videos/ducks/selectors'
import VideoListItem from './VideoListItem'
// import VideoListInfiniteScroller from './VideoListInfiniteScroller'
import {
  fetchVideosByCategoriesRoutine,
  fetchVideosListRoutine
} from '../ducks/actions'
import { filterVideos } from 'utils/videos'
import qs from 'qs'
import { useHistory } from 'react-router-dom'
import { MixedVideo } from 'types/videos'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/videos/utils/events'
import { BouncingLoader } from 'examkrackers-components'
import { COURSE_TYPES } from 'utils/courses'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types/courses'

interface IParsedQuery extends qs.ParsedQs {
  filter?: {
    search: string
    category: string
    'b.id': string
    'bcc.id': string
    'bsubc.id': string
    'sfv.id': string
    __is_watched: string
  }
}

export const VideoList = (): JSX.Element => {
  const dispatch = useDispatch()
  // const studentDetails = useSelector(getStudent)

  // prepare the variable of secondary videos without the background music
  // const hasBGMusicChanged = propOr(
  //   false,
  //   'video_bg_music_enabled',
  //   studentDetails
  // )
  const {
    location: { search }
  } = useHistory()
  const [bookVideosRefetched, setBookVideosRefetched] = useState(false)
  const bookVideosPagination = useSelector(getVideosPagination)

  const parsedQuery: IParsedQuery = qs.parse(search, {
    ignoreQueryPrefix: true
  })
  const videosState = useSelector(selectVideosState)

  const { filter } = parsedQuery
  const category = filter?.category || 'all'
  const bookId = filter?.['b.id'] || ''
  const bccId = filter?.['bcc.id'] || ''
  const searchPhrase = filter?.search || ''
  const subchapterId = filter?.['bsubc.id'] || ''
  const isWatched = filter?.__is_watched
  const videosList = useSelector(getVideosList) as MixedVideo[]
  const bookVideosState = useSelector(selectBookVideosState)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.originalCourseId)

  const endDateId = LocalStorageService.get(
    LOCAL_STORAGE_KEYS.studentCourseEndDateId
  )
  const courseType = LocalStorageService.get(
    LOCAL_STORAGE_KEYS.studentCourseType
  )

  const fetchVideosByCategory = useCallback(
    payload => dispatch(fetchVideosByCategoriesRoutine(payload)),
    [dispatch]
  )
  const fetchVideosFromBooks = useCallback(
    () => dispatch(fetchVideosListRoutine()),
    [dispatch]
  )

  const categoriesToFetch =
    courseType === COURSE_TYPES.live
      ? [
          `review&filter[course_id]=${courseId}`,
          `onboarding`,
          `recordings&filter[course_end_date_id]=${endDateId}`,
          `medreel&filter[course_id]=${courseId}`
        ]
      : [
          `review&filter[course_id]=${courseId}`,
          `onboarding`,
          `medreel&filter[course_id]=${courseId}`
        ]

  const fetchAllVideos = () => {
    fetchVideosByCategory({
      categories: categoriesToFetch
    })
    fetchVideosFromBooks()
  }

  const videosArray = filterVideos(
    videosList,
    searchPhrase,
    category,
    bookId,
    bccId,
    subchapterId,
    isWatched
  )

  const filteredVideos = isFreeTrial
    ? videosArray.filter(video => video.category !== 'medreel')
    : videosArray

  const listWithAlphabeticalOrder = filteredVideos.sort((a, b) =>
    a.title.localeCompare(b.title)
  )

  useEffect(() => {
    fetchVideosByCategory({
      categories: categoriesToFetch
    })
    eventEmitter.on(events.favoriteToggle, fetchAllVideos)

    return () => {
      eventEmitter.off(events.favoriteToggle, fetchAllVideos)
    }
  }, [])

  useEffect(() => {
    if (
      videosState !== 'in-progress' &&
      bookVideosPagination.recordsTotal === 0 &&
      bookVideosRefetched === false
    ) {
      fetchVideosFromBooks()
      setBookVideosRefetched(true)
    }
  }, [])

  return (
    <Container>
      <VideosListContainer>
        {R.uniq(listWithAlphabeticalOrder).map(video => (
          <VideoListItem key={`video-list-item-${video.id}`} video={video} />
        ))}
        {/* <VideoListInfiniteScroller /> */}
        {bookVideosState !== 'done' ? (
          <LoaderWrapper>
            <BouncingLoader />
          </LoaderWrapper>
        ) : null}
      </VideosListContainer>
    </Container>
  )
}

export default VideoList

const Container = styled.div`
  margin-top: 24px;
  flex-grow: 1;
  max-height: calc(100% - 108px);
`

const VideosListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
