export const parseJsonAndTextEnhanced = (
  message: string
): (string | object)[] => {
  const parts: (string | object)[] = []
  let remaining = message

  while (remaining.length > 0) {
    const jsonMatch = remaining.match(/```json\n([\s\S]*?)\n```/)
    if (jsonMatch) {
      const jsonString = jsonMatch[1]
      try {
        const jsonObject = JSON.parse(jsonString)
        parts.push(jsonObject as object)
      } catch (e) {
        parts.push(jsonMatch[0])
      }
      remaining = remaining.substring(jsonMatch[0].length)
      continue
    }

    const nextCodeBlockMatch = remaining.match(/```[\s\S]*?```/)
    if (nextCodeBlockMatch) {
      parts.push(nextCodeBlockMatch[0])
      remaining = remaining.substring(nextCodeBlockMatch[0].length)
      continue
    }

    const nextTextMatch = remaining.match(/^([^\`]*)/)
    if (nextTextMatch) {
      parts.push(nextTextMatch[1])
      remaining = remaining.substring(nextTextMatch[1].length)
      continue
    }
  }
  return parts
}

export const preprocessLaTeX = (text: string): string => {
  // Basic LaTeX delimiters: \(...\) and \[...\]
  let processedText = text.replace(/\\\((.*?)\\\)/g, '$$$1$$')
  processedText = processedText.replace(/\\\[(.*?)\\\]/g, '$$$1$$')
  return processedText
}
