import React, { useState } from 'react'
import styled from 'styled-components'
import { IconButton, NavArrowUpIcon } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAIChatPromptCost } from 'modules/settings/ducks/selectors'
import { SaltyBucksIcon } from 'examkrackers-components'
import { isNilOrEmpty } from 'utils/ramda'

const AIChatInput: React.FC<{
  chatContext: string
  handleSendMessage: (message: string) => void
}> = ({ handleSendMessage, chatContext }) => {
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const promptCost = useSelector(selectAIChatPromptCost)

  const handleOnChange = (e: {
    target: { value: React.SetStateAction<string> }
  }) => {
    const textarea = e.target as HTMLTextAreaElement

    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = 'auto'
    // Set the height to match the content
    textarea.style.height = `${textarea.scrollHeight}px`

    setInput(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      const textarea = document.getElementById(
        'ai-chat-input'
      ) as HTMLTextAreaElement
      if (input.length > 0) {
        handleSendMessage(input)
        setInput('')
        textarea.style.height = '48px'
      }
    }
  }

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (input.length > 0) {
      handleSendMessage(input)
      setInput('')
    }
  }

  return (
    <InputWrapperOuter data-testid='ai-chat-input-outer-wrapper'>
      <InputWrapper
        id='ai-chat-input-wrapper'
        data-testid='ai-chat-input-wrapper'
        onSubmit={handleOnSubmit}
        isDisabled={isNilOrEmpty(chatContext)}
      >
        <StyledInput
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          value={input}
          name={'ai-chat-input'}
          id={'ai-chat-input'}
          disabled={isNilOrEmpty(chatContext)}
        />
        <InputPlaceholder isVisible={isNilOrEmpty(input)}>
          <PlaceHolderWrapper>
            {t('bookViewer.aiChat.placeholder')}
            {' (costs '}
          </PlaceHolderWrapper>
          <StyledSaltyBucksIcon />
          {promptCost + ')'}
        </InputPlaceholder>
        <IconButton
          variant='filled'
          size='small'
          type='submit'
          icon={<NavArrowUpIcon />}
          disabled={!input}
          onClick={handleOnSubmit}
        />
      </InputWrapper>
    </InputWrapperOuter>
  )
}

export default AIChatInput

const InputWrapperOuter = styled.div`
  width: calc(100% - 16px);
`

const InputWrapper = styled.form<{ isDisabled: boolean }>`
  width: 100%;
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.colors.AIChat.input.border}`};
  border-radius: 6px;
  align-items: center;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.main.grey200 : theme.colors.main.white};
  padding: 0 8px 0 0;
  position: relative;
  transition: all 300ms ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;

  &:focus-within {
    border: ${({ theme }) => `1px solid ${theme.colors.main.text}`};
  }
`

const StyledInput = styled.textarea`
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  flex: 1;

  display: inline-block;
  padding: 16px 16px 0 16px;
  resize: none;
  overflow-y: auto;
  box-sizing: border-box;
  height: auto;
  overflow-y: hidden;
  line-height: 16px;
  min-height: 48px;
  max-height: 150px;

  &::placeholder {
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: ${({ theme }) => theme.colors.AIChat.input.placeholder};
  }
`

const StyledSaltyBucksIcon = styled(SaltyBucksIcon)`
  color: ${({ theme }) => theme.colors.AIChat.input.placeholder};
`

const InputPlaceholder = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 16px;
  left: 16px;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.AIChat.input.placeholder};
  pointer-events: none;
  display: flex;
  align-items: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`
const PlaceHolderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`
