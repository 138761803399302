import React from 'react'
import styled from 'styled-components'

const AIEvaluationDashedLines: React.FC = () => {
  return (
    <Container>
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
    </Container>
  )
}

export default AIEvaluationDashedLines

const Container = styled.div`
  height: 39.37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  left: 0;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed ${props => props.theme.colors.main.grey400};
`
