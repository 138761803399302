import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import styled from 'styled-components'

import { BackButton } from 'examkrackers-components'
import TutoringWeeklyScheduleDay from 'modules/tutoring/components/TutoringWeeklyScheduleDay'

import { fetchUpcoming7DayTutoring } from 'services/TutoringScheduleRepository'
import PATHS from 'utils/paths'

export const TutoringSchedule = (): JSX.Element => {
  const [list, setList] = useState([])

  const { t } = useTranslation()
  const history = useHistory()

  const TutoringScheduleList = list.map(day => (
    //@ts-ignore
    <TutoringWeeklyScheduleDay weeklyScheduleDay={day} key={day.date} />
  ))

  const redirectToDashboard = () => history.push(PATHS.dashboard)

  const fetchList = () => {
    const handleSuccess = resp => {
      setList(resp.data || [])
    }

    const handleError = e => {
      console.error(e)
    }

    fetchUpcoming7DayTutoring().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('pages.tutoring')}</title>
      </Helmet>
      <Header>
        <PageTitle>{t('tutoring.title')}</PageTitle>
        <BackButton key='back-button' onClick={redirectToDashboard}>
          {t('tutoring.backCTA')}
        </BackButton>
      </Header>
      <Container>{TutoringScheduleList}</Container>
    </React.Fragment>
  )
}

export default TutoringSchedule

const Container = styled.div`
  padding: 12px 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  border-radius: 6px;
  gap: 15px;
`
const Header = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
`
