import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import APIService from 'services/APIService'
import { GetChatHistoryPayload } from 'types/aichat'

const api = APIService()

const API_URL = process.env.REACT_APP_API_URL

export const sendStream = (message: string, student_book_chapter_id: string) =>
  fetch(`${API_URL}/chat-bot/send-stream`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-student-course-id':
        LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId) || '',
      Authorization: LocalStorageService.get(LOCAL_STORAGE_KEYS.token)
    }),
    body: JSON.stringify({
      message,
      student_book_chapter_id
    })
  })

export const getChatHistory = (payload: GetChatHistoryPayload) =>
  api.get(
    `/chat-bot/history/${payload.student_book_chapter_id}?new_context=${payload.new_context}`
  )
