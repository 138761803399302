import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  SaltyBucksIcon,
  Button,
  IconButton,
  AINewChatIcon
} from 'examkrackers-components'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'
import NumberAnimator from './NumberAnimator'
import { selectAIChatPromptCost } from 'modules/settings/ducks/selectors'
import { useSelector } from 'react-redux'

const AIChatHeader: React.FC<{
  handleClearChat: () => void
  isEvaluation: boolean
  score: number | undefined
  messagesCount: number
  cumulatedScores: number
  cumulatedStudentPromptNumber: number
}> = ({
  handleClearChat,
  isEvaluation,
  score,
  messagesCount,
  cumulatedScores,
  cumulatedStudentPromptNumber
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const promptCost = useSelector(selectAIChatPromptCost)

  const totalCosts = promptCost * cumulatedStudentPromptNumber
  const totalEarnings = cumulatedScores - totalCosts

  const redirectToAIEvaluation = () => {
    history.push(PATHS.aiEvaluation)
  }

  return (
    <HeaderContainerOuter>
      <HeaderContainer isEvaluation={isEvaluation}>
        <WalletField>
          <WalletFieldTitle>{t('bookViewer.aiChat.answer')}:</WalletFieldTitle>
          <WalletFieldAmount>
            {/* hide values on initial state (so before chat asks first question) */}
            {messagesCount > 3 ? (
              <>
                <NumberAnimator value={score ?? 0} />%
              </>
            ) : (
              <span></span>
            )}
          </WalletFieldAmount>
        </WalletField>
        <WalletField>
          <WalletFieldTitle>
            {t('bookViewer.aiChat.calculate')}:
          </WalletFieldTitle>
          <WalletFieldAmount>
            {/* hide values on initial state (so before chat asks first question) */}
            {messagesCount > 3 ? (
              <>
                <NumberAnimator value={score ?? 0} />{' '}
                <span> - {promptCost} = </span>
                <NumberAnimator value={score ? score - promptCost : 0} />
              </>
            ) : (
              <span></span>
            )}
          </WalletFieldAmount>
        </WalletField>
        <WalletField>
          <WalletFieldTitle>
            {t('bookViewer.aiChat.earnings')}:
          </WalletFieldTitle>
          <WalletFieldAmount withoutGap={true}>
            <SaltyBucksIcon />
            <NumberAnimator value={totalEarnings} />
          </WalletFieldAmount>
        </WalletField>
        {!isEvaluation && (
          <Button onClick={redirectToAIEvaluation} size='small' color='blue'>
            {t('bookViewer.aiChat.aiEval')}
          </Button>
        )}
        <IconButton
          variant='filled'
          onClick={handleClearChat}
          icon={<AINewChatIcon />}
          tooltip={t('bookViewer.aiChat.newChatTooltip')}
          tooltipId='new-chat-tooltip'
        />
      </HeaderContainer>
    </HeaderContainerOuter>
  )
}

export default AIChatHeader

const HeaderContainerOuter = styled.div`
  height: 43px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.AIChat.divider}`};
`

const HeaderContainer = styled.div<{ isEvaluation: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ isEvaluation }) => (isEvaluation ? '100%' : 'calc(100% - 55px)')};
`

const WalletField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const WalletFieldTitle = styled.div`
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.8px;
`

const WalletFieldAmount = styled.div<{ withoutGap?: boolean }>`
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  max-width: 150px;
  gap: ${({ withoutGap }) => (withoutGap ? '0px' : '4px')};
  white-space: nowrap;
  line-height: 18.4px;
  align-items: flex-start;
  max-height: 18.4px;
  min-height: 18.4px;
`
