import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import 'katex/dist/katex.min.css'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import { preprocessLaTeX } from '../utils'

const AIChatMessageMarkdown: React.FC<{
  message: string
}> = ({ message }) => {
  const processedMessage = preprocessLaTeX(message)
  return (
    <StyledContainer>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        remarkRehypeOptions={{ passThrough: ['link'] }}
      >
        {processedMessage}
      </ReactMarkdown>
    </StyledContainer>
  )
}

export default AIChatMessageMarkdown

const StyledContainer = styled.div`
  p {
    margin: 8px 0;
  }

  ul {
    list-style-type: disc;
    margin: 8px 0 8px 20px;
  }

  li {
    margin: 4px 0 4px 20px;
  }

  strong {
    font-weight: bold;
  }

  .katex {
    font-size: 1.1em;
  }

  .katex-display {
    margin: 1em 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px 0;
  }

  .katex .mord.text {
    font-family: inherit;
  }

  .katex .mspace {
    margin-right: 0.2em;
  }

  .katex .mord.text > .textsf {
    font-family: inherit;
  }

  .latex-error {
    color: red;
    font-style: italic;
  }
`
