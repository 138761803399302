import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CloseIcon, SaltyBucksIcon } from 'examkrackers-components'
import { fetchStudentsLastEarnings } from 'services/StudentService'
import { formatNumberWithDecimal } from 'utils/numbers'

import { pathOr, propOr } from 'ramda'

import { Earning } from 'types/saltyBucksEarnings'

export const SaltyBucksHistory = ({
  handleClose,
  containerRef,
  isSaltyHistoryOpened
}) => {
  const [data, setData] = useState(null)
  const { t } = useTranslation()
  useEffect(() => {
    fetchStudentsLastEarnings()
      // @ts-ignore
      .then(result => setData(pathOr([], ['data', 'data'], result)))
      .catch(console.error)
  }, [])

  // @ts-ignore
  const earningsList = data?.map((earning: Earning) => {
    const operationType = propOr('unknown', 'operation_type', earning)
    const isOperationTypeOutcome = operationType === 'outcome'
    const log = t(
      `navigation.saltyBucksDropDown.logs.${pathOr(
        'unknown',
        ['reference_type'],
        earning
      )}.${pathOr('unknown', ['operation_subtype'], earning)}`
    )
    return (
      <RowWrapper
        key={earning.id}
        isOperationTypeOutcome={isOperationTypeOutcome}
      >
        <CauseWrapper>{log}</CauseWrapper>
        <AmountWrapper>
          <IconWrapper>
            <SaltyBucksIcon />
          </IconWrapper>
          {isOperationTypeOutcome ? '-' : null}{' '}
          {formatNumberWithDecimal(Math.abs(earning.amount))}
        </AmountWrapper>
      </RowWrapper>
    )
  })
  return (
    <HistoryWrapper
      ref={containerRef}
      isSaltyHistoryOpened={isSaltyHistoryOpened}
    >
      <Title>{t('navigation.saltyBucksDropDown.title')}</Title>
      <CloseIconWrapper onClick={handleClose}>
        <CloseIcon />
      </CloseIconWrapper>
      <Headers>
        <HeadersTextWrapper>
          {t('navigation.saltyBucksDropDown.reason')}
        </HeadersTextWrapper>
        <HeadersTextWrapper>
          {t('navigation.saltyBucksDropDown.amount')}
        </HeadersTextWrapper>
      </Headers>
      <div>{earningsList}</div>
    </HistoryWrapper>
  )
}

export default SaltyBucksHistory

const HistoryWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 0px;
  width: 404px;
  height: auto;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: left;
  flex: 1;
  margin-right: 6px;
  padding: 16px;
  z-index: ${({ theme, isSaltyHistoryOpened }) =>
    isSaltyHistoryOpened ? theme.zIndex.mainMenu + 20 : theme.zIndex.menu - 15};
`
const Headers = styled.div`
  color: ${({ theme }) =>
    theme.colors.topNav.totalSaltyBucksAndTimer.saltyHistoryHeader};
  margin-left: 1px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.colors.topNav.totalSaltyBucksAndTimer.dropdownLineBreak};
`
const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.main.heading};
  margin-bottom: 16px;
`

const HeadersTextWrapper = styled.div`
  margin-right: 36px;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 11px;
`
const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`
const RowWrapper = styled.div`
  color: ${({ theme, isOperationTypeOutcome }) =>
    isOperationTypeOutcome
      ? theme.colors.main.error500
      : theme.colors.main.heading};
  margin-left: 1px;
  display: flex;
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.colors.topNav.totalSaltyBucksAndTimer.dropdownLineBreak};
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  &:last-of-type {
    border: none;
    padding-bottom: 0px;
  }
`
const CauseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`

const AmountWrapper = styled.div`
  padding-right: 21px;
  display: flex;
  flex-direction: row;
`

const IconWrapper = styled.div`
  padding-top: 1px;
  padding-right: 5px;
`
