import React from 'react'
import styled from 'styled-components'
import { Loader } from 'examkrackers-components'

const AIChatMessageLoader: React.FC = () => {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  )
}

export default AIChatMessageLoader

const LoaderWrapper = styled.div`
  max-width: max-content;
  padding: 8px;
  margin: 0 auto;
  border-bottom: none;
`
