import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from 'styled-components'
import { SingleSelect } from 'examkrackers-components'
import qs from 'qs'

import { AI_EVALUATION_PARAMS } from 'utils/aiEvaluation'
import { getOptionByValue } from 'utils/form'
import { isNilOrEmpty } from 'utils/ramda'
import { selectAllAITutorBooks } from 'modules/books/ducks/selectors'
import { Chapter } from 'types'

const AIEvaluationBookChapterFilter = () => {
  const { t } = useTranslation()
  const {
    replace,
    location: { pathname, search }
  } = useHistory()

  const books = useSelector(selectAllAITutorBooks)
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const selectedBook = R.pathOr('', [AI_EVALUATION_PARAMS.bookId], parsedQuery)
  const selectedChapter = R.pathOr(
    '',
    [AI_EVALUATION_PARAMS.chapterId],
    parsedQuery
  )

  // @ts-ignore
  const chapters: Chapter[] = R.pipe(
    R.find(R.propEq('book_id', selectedBook)),
    R.propOr([], 'chapters')
  )(books)

  const sortByOrder = R.sortBy(R.prop('order'))
  const sortedBookChapters = sortByOrder(chapters)

  const bookOptions = books
    .map(book => ({
      label: book.title,
      value: book.book_id
    }))
    .filter(option => !option.label.toLocaleLowerCase().includes('cars'))

  const chapterOptions = sortedBookChapters.map(chapter => {
    return {
      label: `${chapter.order}. ${chapter.title}`,
      value: chapter.id
    }
  })

  const handleBookChange = option => {
    const bookFilter = isNilOrEmpty(option)
      ? {}
      : { [AI_EVALUATION_PARAMS.bookId]: option.value }

    const newQuery = {
      ...R.omit(
        [AI_EVALUATION_PARAMS.bookId, AI_EVALUATION_PARAMS.chapterId],
        parsedQuery
      ),
      ...bookFilter
    }

    replace(`${pathname}?${qs.stringify(newQuery, { encode: false })}`)
  }

  const handleChapterChange = option => {
    const chapterFilter = isNilOrEmpty(option)
      ? {}
      : { [AI_EVALUATION_PARAMS.chapterId]: option.value }

    const newQuery = {
      ...R.dissoc(AI_EVALUATION_PARAMS.chapterId, parsedQuery),
      ...chapterFilter
    }

    replace(`${pathname}?${qs.stringify(newQuery, { encode: false })}`)
  }

  return (
    <FiltersWrapper id='ai-evaluation-book-chapter-filters'>
      <StyledSelect
        isClearable
        removeMargin
        removeTopLabel
        id='select-book'
        label={t('aiEvaluation.chatCollapse.book')}
        placeholder={t('aiEvaluation.chatCollapse.book')}
        options={bookOptions}
        value={getOptionByValue(selectedBook)(bookOptions)}
        onChange={handleBookChange}
      />
      <StyledSelect
        isClearable
        removeMargin
        removeTopLabel
        id='select-chapter'
        label={t('aiEvaluation.chatCollapse.chapter')}
        placeholder={t('aiEvaluation.chatCollapse.chapter')}
        options={chapterOptions}
        value={getOptionByValue(selectedChapter)(chapterOptions)}
        onChange={handleChapterChange}
        disabled={isNilOrEmpty(selectedBook)}
      />
    </FiltersWrapper>
  )
}

export default AIEvaluationBookChapterFilter

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 8px;
  margin: 0 8px;
  z-index: 1;

  * {
    font-size: 14px;
  }

  & > div {
    width: 236px;

    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }

  & > div > div {
    margin: 0 !important;
  }

  animation: fadeIn 1000ms ${({ theme }) => theme.transitions.easing.easeInOut}
    forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const StyledSelect = styled(SingleSelect)`
  min-width: 236px;

  div {
    overflow-x: hidden;
  }
`
