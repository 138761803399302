import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

function TutoringWeeklyScheduleDayEmptyState({
  text,
  cta
}: {
  text?: string
  cta?: React.ReactElement | null
}) {
  const { t } = useTranslation()

  return (
    <Container>
      <EmptyStateImage src='/assets/illustrations/SleepySalty.svg' />
      <EmptyStateText>{text || t('tutoring.emptyState')}</EmptyStateText>
      <EmptyStateCtaContainer>{cta}</EmptyStateCtaContainer>
    </Container>
  )
}

export default TutoringWeeklyScheduleDayEmptyState

const EmptyStateImage = styled.img`
  max-width: 93px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  padding: 7px 8px;
  gap: 8px;
  max-height: 75px;
`

const EmptyStateText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
`

const EmptyStateCtaContainer = styled.div`
  margin-left: auto;
`
