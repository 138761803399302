import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import PATHS from 'utils/paths'
import { TTutorClass } from 'types/tutoring'
import { fetchUpcomingTutoring } from 'services/TutoringScheduleRepository'
import TutoringWeeklyScheduleTutor from './TutoringWeeklyScheduleTutor'
import { getUpcomingClasses } from '../utils/tutoringClasses'
import TutoringWeeklyScheduleDayEmptyState from './TutoringWeeklyScheduleDayEmptyState'
import { Button } from 'examkrackers-components'

export const WidgetTutoringWeeklySchedule = (): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()
  const [upcomingTutoringList, setUpcomingTutoringList] = useState<
    TTutorClass[]
  >([])

  const fetchList = () => {
    const handleSuccess = resp => {
      setUpcomingTutoringList(resp.data)
    }

    const handleError = e => {
      console.error(e)
    }

    fetchUpcomingTutoring().then(handleSuccess).catch(handleError)
  }

  const upcomingClasses = getUpcomingClasses(upcomingTutoringList)
  const TutoringScheduleList = upcomingClasses.map(tutorClass => (
    <TutoringWeeklyScheduleTutor tutorClass={tutorClass} key={tutorClass.id} />
  ))
  const redirectToFullSchedule = () => {
    history.push(PATHS.tutoringSchedule)
  }

  const EmptyStateCta = (
    <Button
      id='button-empty-state-cta'
      name='button-empty-state-cta'
      onClick={redirectToFullSchedule}
      color='blue'
      size='small'
    >
      {t('tutoring.emptyStateWidgetCTA')}
    </Button>
  )

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <>
      <Header>
        <WidgetTitle>{t('tutoring.widgetTitle')}</WidgetTitle>
        <RedirectButton onClick={redirectToFullSchedule}>
          {t('tutoring.widgetCTA')}
        </RedirectButton>
      </Header>
      <Container>
        {TutoringScheduleList}
        {upcomingClasses.length === 0 && (
          <>
            <TutoringWeeklyScheduleDayEmptyState
              text={t('tutoring.emptyStateWidget')}
              cta={EmptyStateCta}
            />
            <TutoringWeeklyScheduleDayEmptyState
              text={t('tutoring.emptyStateWidget')}
              cta={EmptyStateCta}
            />
            <TutoringWeeklyScheduleDayEmptyState
              text={t('tutoring.emptyStateWidget')}
              cta={EmptyStateCta}
            />
          </>
        )}
        {upcomingClasses.length === 1 && (
          <>
            <TutoringWeeklyScheduleDayEmptyState
              text={t('tutoring.emptyStateWidget')}
              cta={EmptyStateCta}
            />
            <TutoringWeeklyScheduleDayEmptyState
              text={t('tutoring.emptyStateWidget')}
              cta={EmptyStateCta}
            />
          </>
        )}
        {upcomingClasses.length === 2 && (
          <TutoringWeeklyScheduleDayEmptyState
            text={t('tutoring.emptyStateWidget')}
            cta={EmptyStateCta}
          />
        )}
      </Container>
    </>
  )
}

export default WidgetTutoringWeeklySchedule

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
`

const WidgetTitle = styled.div`
  font-size: 18px;
  font-weight: 900;
  line-height: 25.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-family: 'Arial Black';
  color: ${({ theme }) => theme.colors.main.black};
`

const RedirectButton = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.main.text};
`
