import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Modal, Button } from 'examkrackers-components'
import { TTutorClass } from 'types'
import {
  convertTo12HourFormat,
  isCurrentESTTimeBetween,
  DATE_FORMATS,
  formatDate,
  isESTTimeAsToday,
  isESTTimeWithin15Minutes
} from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'

interface ModalTutorSessionProps {
  tutorClass: TTutorClass
}

export const ModalTutorSession = (props: ModalTutorSessionProps) => {
  const { class_date, class_time, class_time_end, meeting_url } =
    props.tutorClass
  const isTodaysClass = isESTTimeAsToday(class_date, class_time)
  const is15minsToClass = isESTTimeWithin15Minutes(class_date, class_time)
  const isInProgress = isCurrentESTTimeBetween(
    class_date,
    class_time,
    class_time_end
  )
  const is15minToClassOrInProgress = is15minsToClass || isInProgress
  const SessionEnabled = isTodaysClass && is15minToClassOrInProgress

  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSubmit = () => {
    if (isNotNilOrEmpty(meeting_url)) {
      window.open(meeting_url, '_blank')
    }
  }

  return (
    <>
      {SessionEnabled ? (
        <Button
          id='button-join-sessiom'
          name={`join-session-${props.tutorClass.id}`}
          onClick={handleOpen}
          color='blue'
          size='small'
        >
          {t('tutoring.joinSession')}
        </Button>
      ) : (
        <Button
          id='button-join-sessiom'
          name={`join-session-${props.tutorClass.id}`}
          onClick={handleOpen}
          color='transparent'
          size='small'
          variant='contained'
        >
          <MoreInfoCTA>{t('tutoring.moreInfo')}</MoreInfoCTA>
        </Button>
      )}
      <Modal
        title={t('tutoring.modal.title')}
        open={open}
        handleClose={handleClose}
        hasBorder
      >
        <div>
          <Container>
            <TutorImage
              src={
                props.tutorClass.tutor?.image_url ||
                '/assets/user/userPlaceholder.svg'
              }
            />
            <TutorDetails>
              <TutorScheduleDay>
                {formatDate(
                  props.tutorClass.class_date,
                  DATE_FORMATS.describedDay
                )}
              </TutorScheduleDay>
              <TutorScheduleTime>
                {convertTo12HourFormat(props.tutorClass.class_time)} -{' '}
                {convertTo12HourFormat(props.tutorClass.class_time_end)} ET
              </TutorScheduleTime>
              <TutorName>{props.tutorClass?.tutor?.name || ''}</TutorName>
              <TutorBio>{props.tutorClass?.tutor?.bio || ''}</TutorBio>
            </TutorDetails>
          </Container>
          <ButtonsContainer>
            <Button
              id='cancel-join-with-zoom'
              onClick={handleClose}
              type='button'
              color='tertiary'
              variant='contained'
            >
              {t('tutoring.modal.cancelCTA')}
            </Button>
            <Button
              color='secondary'
              id='submit-join-with-zoom'
              disabled={!SessionEnabled}
              onClick={handleSubmit}
            >
              {t('tutoring.modal.submitCTA')}
            </Button>
          </ButtonsContainer>
        </div>
      </Modal>
    </>
  )
}

export default ModalTutorSession

const MoreInfoCTA = styled.span`
  color: ${({ theme }) => theme.colors.main.text};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary500};
  }
`

const TutorImage = styled.img`
  width: 73px;
  height: 73px;
  border-radius: 50%;
  align-self: flex-start;
  margin-top: 70px;
`

const Container = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.main.primary200};
  gap: 16px;
  margin: 0 -20px;
  padding: 32px;
`

const TutorDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const TutorName = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.4px;
  margin-top: 8px;
`

const TutorScheduleDay = styled.div`
  font-size: 18px;
  font-weight: 900;
  line-height: 25.38px;
  text-align: left;
  font-family: 'Arial Black';
`

const TutorScheduleTime = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  color: ${({ theme }) => theme.colors.main.secondary600};
`

const TutorBio = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  max-width: 473px;
  text-align: left;
`

const ButtonsContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  button {
    min-width: 154px;
  }
`
