import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal, VideoPlayer } from 'examkrackers-components'
import styled from 'styled-components'

const ModalLearnHowToStudy = (): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <React.Fragment>
      <WatchVideo onClick={handleOpen}>
        {t('dashboard.stepsForSuccess.cta')}
      </WatchVideo>
      <Modal
        title={t('dashboard.stepsForSuccess.videoModal.title')}
        open={open}
        handleClose={handleClose}
      >
        <ModalContent>
          <Trans i18nKey='dashboard.stepsForSuccess.videoModal.description'>
            0 <strong>1</strong> 2
          </Trans>
          <VideoContainer>
            <VideoPlayer
              progressInterval={5000}
              url='https://player.vimeo.com/video/1002758143?h=283afd5fb6'
              controls
              width={'500px'}
              height={'280px'}
            />
          </VideoContainer>
        </ModalContent>
      </Modal>
    </React.Fragment>
  )
}

export default ModalLearnHowToStudy

const ModalContent = styled.div`
  max-width: 600px;

  strong {
    font-weight: 600;
  }
`
const WatchVideo = styled.button`
  all: unset;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.text};
  text-decoration: underline;
  cursor: pointer;
  line-height: 25px;
`

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
