import React from 'react'
import { MathJaxContext } from 'better-react-mathjax'
import AIChatMessageMarkdown from './AIChatMessageMarkdown'
import { parseJsonAndTextEnhanced } from '../utils'
import styled from 'styled-components'

interface AIChatMessageWrapperProps {
  message: string
}

const AIChatMessageWrapper: React.FC<AIChatMessageWrapperProps> = ({
  message
}) => {
  const parsedMessage = parseJsonAndTextEnhanced(message)

  return (
    <MathJaxContext>
      <StyledContainer>
        {parsedMessage
          .filter(item => typeof item === 'string')
          .map((item, index) => (
            <AIChatMessageMarkdown key={index} message={item} />
          ))}
      </StyledContainer>
    </MathJaxContext>
  )
}

export default AIChatMessageWrapper

const StyledContainer = styled.div`
  pre {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`
