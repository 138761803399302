import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fetchSaltyBucksSettingsRoutine } from 'modules/settings/ducks/actions'
import AIChatInput from './AIChatInput'
import AIChatMessages from './AIChatMessages'
import AIChatHeader from './AIChatHeader'
import useAiChat from '../hooks/useAiChat'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { AI_EVALUATION_PARAMS } from 'utils/aiEvaluation'
import qs from 'qs'
import * as R from 'ramda'

const AIChat: React.FC<{ chatContext: string; isEvaluation: boolean }> = ({
  chatContext,
  isEvaluation
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    messages,
    handleSendMessage,
    streamedMessage,
    isLoading,
    handleClearChat,
    score,
    cumulatedScores,
    cumulatedStudentPromptNumber
  } = useAiChat(chatContext)
  const {
    location: { search }
  } = useHistory()

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const selectedBook = R.pathOr('', [AI_EVALUATION_PARAMS.bookId], parsedQuery)

  const messagesCount = messages.length

  const fetchSaltyBucksSetting = useCallback(() => {
    dispatch(fetchSaltyBucksSettingsRoutine())
  }, [dispatch])

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchSaltyBucksSetting()
  }, [])

  useEffect(() => {
    fetchStudentDetails()
  }, [cumulatedStudentPromptNumber])

  const errorMessage = () => {
    switch (true) {
      case isNotNilOrEmpty(chatContext):
        return t('bookViewer.aiChat.validityWarning')
      case isNilOrEmpty(selectedBook):
        return t('bookViewer.aiChat.errorBookAndChapter')
      case isNilOrEmpty(chatContext):
        return t('bookViewer.aiChat.errorChapter')
      default:
        return t('bookViewer.aiChat.validityWarning')
    }
  }

  return (
    <ChatContainer>
      <AIChatHeader
        isEvaluation={isEvaluation}
        handleClearChat={handleClearChat}
        score={score ?? 0}
        messagesCount={messagesCount}
        cumulatedScores={cumulatedScores}
        cumulatedStudentPromptNumber={cumulatedStudentPromptNumber}
      />
      <AIChatMessages
        isLoading={isLoading}
        messages={messages}
        streamedMessage={streamedMessage}
      />
      <AIChatInput
        handleSendMessage={handleSendMessage}
        chatContext={chatContext}
      />
      <ChatValidityWarning hasError={isNilOrEmpty(chatContext)}>
        {errorMessage()}
      </ChatValidityWarning>
    </ChatContainer>
  )
}

export default AIChat

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;
`

const ChatValidityWarning = styled.div<{ hasError: boolean }>`
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.main.error500 : theme.colors.AIChat.warning};

  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 3px;
`
