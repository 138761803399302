import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ChapterDetails } from 'types/dashboard'
import { isNotNilOrEmpty } from 'utils/ramda'

interface SingleCardProps {
  evaluationScores?: ChapterDetails
  all?: number
  isAll?: boolean
  isWeakest?: boolean
  isStrongest?: boolean
  redirectToAiEvaluation?: () => void
}
const SingleCard = ({
  evaluationScores,
  all,
  isAll,
  isStrongest,
  redirectToAiEvaluation
}: SingleCardProps): JSX.Element => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const isEvaluationScores = isNotNilOrEmpty(evaluationScores)

  const handleProgressClick = () => {
    const url = `/books/${evaluationScores?.original_book_id}/chapter/${
      Number(evaluationScores?.order) !== 0 ? evaluationScores?.order : 1
    }/part/1`

    push(url)
  }

  return (
    <>
      {isAll ? (
        <MainContainer>
          <PercentileBarsWrapper>
            <Bars>
              <MainExamBar>
                <PercentileBar>
                  <ValueofPercentage>{all}%</ValueofPercentage>
                  <BackgroundBar color='gold'>
                    <FillBar color='gold' height={all} />
                  </BackgroundBar>
                  <SectionTitle>{t('dashboard.aiWidget.overall')}</SectionTitle>
                  <SubTitleAverage>
                    {t('dashboard.aiWidget.allChapters')}
                  </SubTitleAverage>
                </PercentileBar>
              </MainExamBar>
            </Bars>
          </PercentileBarsWrapper>
        </MainContainer>
      ) : (
        <MainContainer>
          <PercentileBarsWrapper>
            <Bars>
              <MainExamBar>
                <PercentileBar>
                  <ValueofPercentage>
                    {isEvaluationScores
                      ? `${evaluationScores?.average_score}%`
                      : '0%'}
                  </ValueofPercentage>
                  <BackgroundBar
                    barColor={
                      evaluationScores ? evaluationScores?.tag_colour : 'gold'
                    }
                  >
                    <FillBar
                      barColor={evaluationScores?.tag_colour}
                      height={evaluationScores?.average_score}
                    />
                  </BackgroundBar>
                  <SectionTitle>
                    {isStrongest
                      ? t('dashboard.aiWidget.strongestArea')
                      : t('dashboard.aiWidget.weakestArea')}
                  </SectionTitle>
                  {isEvaluationScores ? (
                    <SubTitle onClick={handleProgressClick}>
                      {` ${evaluationScores?.tag} ${evaluationScores?.order}`}
                    </SubTitle>
                  ) : (
                    <SubTitle onClick={redirectToAiEvaluation}>
                      Start using AI Tutor{' '}
                    </SubTitle>
                  )}
                  <BottomTitle>{evaluationScores?.title}</BottomTitle>
                </PercentileBar>
              </MainExamBar>
            </Bars>
          </PercentileBarsWrapper>
        </MainContainer>
      )}
    </>
  )
}

export default SingleCard

const MainContainer = styled.div`
  width: 240px;
  height: 185px;
  padding: 15px 10px;
  margin-bottom: 16px;
  margin-right: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  overflow: hidden;
`

const PercentileBarsWrapper = styled.div``

const MainExamBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`

const Bars = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 16px;
`

const PercentileBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

const BackgroundBar = styled.div<{
  barColor: string
}>`
  position: relative;

  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: 6px;
  border: 1px solid
    ${({ theme, barColor }) =>
      barColor ? theme.colors.tags[barColor]?.backgroundActive : 'gold'};
  height: 67px;
  width: 25px;
  color: ${({ theme, barColor }) =>
    barColor ? theme.colors.tags[barColor]?.backgroundActive : 'gold'};
`

const FillBar = styled.div<{
  barColor: string
}>`
  position: absolute;
  bottom: 0;
  border-radius: 4px;
  background-color: ${({ theme, barColor }) =>
    barColor ? theme.colors.tags[barColor]?.backgroundActive : 'gold'};
  height: ${({ height }) => height}%;
  width: 100%;
  color: ${({ theme, barColor }) =>
    barColor ? theme.colors.tags[barColor]?.backgroundActive : 'gold'};
`
const ValueofPercentage = styled.p`
  font-size: 14px;
  font-weight: 700;
`
const SectionTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
`
const SubTitleAverage = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`
const SubTitle = styled.div`
  font-size: 14px;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1.5px;
  color: #444444;
  cursor: pointer;
`
const BottomTitle = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 11.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
