import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'examkrackers-components'
import styled from 'styled-components'

interface DefaultCardProps {
  redirectToAiEvaluation: () => void
}
const DefaultCard = ({ redirectToAiEvaluation }: DefaultCardProps) => {
  const { t } = useTranslation()

  return (
    <MainContainer>
      <MainExamBar>
        <SectionTitle>{t('dashboard.aiWidget.improveScore')}</SectionTitle>

        <Button color='secondary' onClick={redirectToAiEvaluation}>
          {t('dashboard.aiWidget.button')}
        </Button>
        <SubTitle>{t('dashboard.aiWidget.moreUsage')}</SubTitle>
      </MainExamBar>
    </MainContainer>
  )
}

export default DefaultCard

const MainContainer = styled.div`
  width: 240px;
  height: 185px;
  padding: 8px 10px;
  margin-bottom: 16px;
  gap: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
`

const MainExamBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 185px;
`

const SectionTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  width: 150px;
  text-align: center;
  margin-bottom: 15px;
`
const SubTitle = styled.div`
  margin-top: 15px;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
