export default {
  unavailableForFreeTrialHoverEnter: 'unavailable_for_free_trial_hover_enter',
  unavailableForFreeTrialHoverLeave: 'unavailable_for_free_trial_hover_leave',
  plHoverEnter: ' plHoverEnter',
  plHoverLeave: 'plHoverLeave',
  chartTooltipEnter: 'chart_tooltip_enter',
  chartTooltipLeave: 'chart_tooltip_leave',
  calendarEventStatusChanged: 'calendarEventStatusChanged',
  calendarEventReordered: 'calendarEventReordered',
  taskStatusChanged: 'taskStatusChanged'
}
