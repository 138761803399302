import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { BouncingLoader } from 'examkrackers-components'
import CalendarSettingsInit from './CalendarSettingsInit'
import CalendarController from 'modules/calendar/CalendarController'
import { CalendarContext, CalendarContextProvider } from 'hooks/CalendarContext'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
  apiMove,
  apiMoveToArchive,
  apiReorder,
  ARCHIVE_TAB_TYPES,
  uiMove,
  uiMoveToArchive,
  uiReorder,
  countStudyDaysInRange
} from 'utils/calendar'
import {
  addManualCalendarTaskRoutine,
  setManualCalendarActiveTabRoutine,
  updateManualCalendarTasksRoutine
} from 'modules/calendar/ducks/actions'
import { equals, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext } from 'react-beautiful-dnd'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import OneWeekSubscriptionModal from 'modules/calendar/OneWeekSubscriptionModal'
import FreeTrialModal from 'modules/calendar/FreeTrialModal'
import PATHS from 'utils/paths'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'
import { useHistory } from 'react-router-dom'

const EventCalendar: React.FC = () => {
  const { isLoading, isConfigMode, lists, setLists, archiveEvents } =
    useContext(CalendarContext)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { push } = useHistory()
  const currentCourse: Course = useSelector(getCurrentCourse)
  const amountOfDays =
    currentCourse.metadata && JSON.parse(currentCourse.metadata)
  const parsedAmountOfDays =
    amountOfDays && propOr(0, 'days_amount', amountOfDays)
  const isOneWeekSubscription = parsedAmountOfDays == 7
  const CourseStartDate = currentCourse.calendar_start_at
  const CourseEndDate = currentCourse?.exam_at
  const prioriDays = currentCourse?.prioridays
  const courseType = currentCourse?.type
  const isLiveCourse = courseType === 'live_course'

  const numberOfStudentsStudyDays = countStudyDaysInRange(
    CourseStartDate as string,
    CourseEndDate as string,
    prioriDays as []
  )
  const hasStudentsEnoughStudyDays = numberOfStudentsStudyDays >= 11

  const isFreeTrial = currentCourse?.type === 'free_trial'
  const isCalendarAvailable = currentCourse?.original?.is_calendar_enabled

  useEffect(() => {
    if (!isCalendarAvailable) {
      push(PATHS.dashboardLoading)
    }
  }, [isCalendarAvailable])

  if (isLoading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  const onDragEnd = async result => {
    const { source, destination, draggableId } = result

    const normalizedDraggableId = draggableId.replace('-custom', '')

    const sourceId = source.droppableId
    const destinationId = destination.droppableId
    const isFromArchive = sourceId.includes('events-archive')
    const isToArchive = destinationId.includes('events-archive')

    // no destination
    if (!destination) {
      return
    }

    // Archive reorder
    if (isFromArchive && isToArchive) {
      if (source.droppableId === destination.droppableId) {
        const uiReorderItems = uiReorder(
          archiveEvents,
          source.index,
          destination.index
        )

        dispatch(
          updateManualCalendarTasksRoutine({
            list: uiReorderItems
          })
        )
      }

      return
    }

    // Move from archive to calendar
    if (isFromArchive && !isToArchive) {
      const uiMoveResult = uiMove(
        archiveEvents,
        lists[destinationId],
        source,
        destination,
        true,
        destinationId
      )

      dispatch(
        updateManualCalendarTasksRoutine({
          list: uiMoveResult.source
        })
      )

      setLists(prev => ({
        ...prev,
        [destinationId]: uiMoveResult.destination
      }))

      const apiMoveResult = await apiMove(
        archiveEvents,
        lists[destinationId],
        source,
        destination,
        normalizedDraggableId,
        true,
        false
      )

      if (
        !uiMoveResult.source.every((_, index) => {
          return (
            uiMoveResult.source[index].event_date ===
            apiMoveResult.source[index].event_date
          )
        }) ||
        !uiMoveResult.destination.every((_, index) => {
          return (
            uiMoveResult.destination[index].event_date ===
            apiMoveResult.destination[index].event_date
          )
        })
      ) {
        setLists(prev => ({
          ...prev,
          [destinationId]: apiMoveResult.destination
        }))

        dispatch(
          updateManualCalendarTasksRoutine({
            list: apiMoveResult.source
          })
        )
      }

      return
    }
    // From calendar to archive
    if (!isFromArchive && isToArchive) {
      if (draggableId.includes('custom')) {
        dispatch(
          showToastRoutine({
            key: 'It is not possible to archive a custom event',
            severity: SEVERITY.warning
          })
        )

        return
      } else {
        const uiMoveResult = uiMoveToArchive(lists[sourceId], source)

        setLists(prev => ({
          ...prev,
          [sourceId]: uiMoveResult.source
        }))

        const apiMoveResult = await apiMoveToArchive(
          lists[sourceId],
          source,
          normalizedDraggableId
        )

        if (isNotNilOrEmpty(apiMoveResult.event)) {
          dispatch(
            addManualCalendarTaskRoutine({
              event: apiMoveResult.event
            })
          )

          const tab = ARCHIVE_TAB_TYPES[apiMoveResult.event.type]

          dispatch(setManualCalendarActiveTabRoutine({ activeTab: tab }))
        }

        if (!equals(uiMoveResult.source, apiMoveResult.source)) {
          setLists(prev => ({
            ...prev,
            [sourceId]: apiMoveResult.source
          }))
        }

        return
      }
    }

    // Calendar Moves
    if (!isFromArchive && !isToArchive) {
      // Same cell
      if (source.droppableId === destination.droppableId) {
        const uiReorderItems = uiReorder(
          lists[sourceId],
          source.index,
          destination.index
        )

        setLists(prev => ({
          ...prev,
          [sourceId]: uiReorderItems
        }))

        const apiReorderItems = await apiReorder(
          lists[sourceId],
          source.index,
          destination.index
        )

        if (!equals(uiReorderItems, apiReorderItems)) {
          setLists(prev => ({
            ...prev,
            [sourceId]: apiReorderItems
          }))
        }
      } else {
        // Different cell
        const uiMoveResult = uiMove(
          lists[sourceId],
          lists[destinationId],
          source,
          destination
        )

        setLists(prev => ({
          ...prev,
          [sourceId]: uiMoveResult.source,
          [destinationId]: uiMoveResult.destination
        }))

        const apiMoveResult = await apiMove(
          lists[sourceId],
          lists[destinationId],
          source,
          destination,
          normalizedDraggableId
        )

        if (
          !uiMoveResult.source.every((_, index) => {
            return (
              uiMoveResult.source[index].event_date ===
              apiMoveResult.source[index].event_date
            )
          }) ||
          !uiMoveResult.destination.every((_, index) => {
            return (
              uiMoveResult.destination[index].event_date ===
              apiMoveResult.destination[index].event_date
            )
          })
        ) {
          setLists(prev => ({
            ...prev,
            [sourceId]: apiMoveResult.source,
            [destinationId]: apiMoveResult.destination
          }))
        }
      }
    }
  }

  return isConfigMode ? (
    <CalendarSettingsInit />
  ) : (
    <>
      <Helmet>
        <title>{t('pages.calendar')}</title>
      </Helmet>
      {(isOneWeekSubscription || !hasStudentsEnoughStudyDays) &&
        !isFreeTrial &&
        !isLiveCourse && <OneWeekSubscriptionModal />}
      {isFreeTrial && <FreeTrialModal />}
      <DragDropContext onDragEnd={onDragEnd}>
        <CalendarController />
      </DragDropContext>
    </>
  )
}

const Wrapped: React.FC = () => {
  return (
    <CalendarContextProvider>
      <EventCalendar />
    </CalendarContextProvider>
  )
}

export default Wrapped

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
