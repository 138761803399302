import React, { useEffect } from 'react'
import styled from 'styled-components'

import AIChatMessageStudent from './AIChatMessageStudent'
import AIChatMessageTutor from './AIChatMessageTutor'
import AIChatMessageLoader from './AIChatMessageLoader'

const AIChatMessages: React.FC<{
  messages: { role: string; contents: string }[]
  streamedMessage: string
  isLoading: boolean
}> = ({ messages, streamedMessage, isLoading }) => {
  const messagesContainerRef = React.useRef(null)

  const renderMessages = messages.map((message, index) =>
    message.role === 'bot' ? (
      <AIChatMessageTutor
        message={message}
        key={`chat-message-${index}`}
      />
    ) : (
      <AIChatMessageStudent message={message} key={`chat-message-${index}`} />
    )
  )

  const renderStreamedMessage = streamedMessage ? (
    <AIChatMessageTutor
      message={{
        role: 'bot',
        contents: streamedMessage
      }}
    />
  ) : (
    <AIChatMessageLoader />
  )

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      ;(messagesContainerRef.current as HTMLDivElement).scrollTop = (
        messagesContainerRef.current as HTMLDivElement
      ).scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, streamedMessage])

  return (
    <MessagesContainer ref={messagesContainerRef}>
      {renderMessages}
      {isLoading && renderStreamedMessage}
    </MessagesContainer>
  )
}

export default AIChatMessages

const MessagesContainer = styled.div`
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  gap: 8px;
  flex: 1;
  padding: 16px 0;
`
