import React from 'react'
import styled, { keyframes } from 'styled-components'
import { SkeletonRect } from 'components/Skeleton/SkeletonLoader'

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const StepsForSuccessSkeletonLoader = () => (
  <WidgetContainer>
    <SkeletonRect height='40px' width='60%' />
    <StepsContainer>
      {[...Array(4)].map((_, i) => (
        <StepItem key={i}>
          <SkeletonRect height='24px' width='80%' />
          <SkeletonRect height='16px' width='60%' />
        </StepItem>
      ))}
    </StepsContainer>
  </WidgetContainer>
)

export const NextTasksSkeletonLoader = () => (
  <WidgetContainer>
    <SkeletonRect height='40px' width='50%' />
    <TasksContainer>
      {[...Array(3)].map((_, i) => (
        <TaskItem key={i}>
          <SkeletonRect height='60px' width='100%' />
        </TaskItem>
      ))}
    </TasksContainer>
  </WidgetContainer>
)

export const CalendarSkeletonLoader = () => (
  <WidgetContainer>
    <SkeletonRect height='40px' width='40%' />
    <CalendarGrid>
      {[...Array(35)].map((_, i) => (
        <SkeletonRect key={i} height='40px' width='40px' />
      ))}
    </CalendarGrid>
  </WidgetContainer>
)

export const TutorsSkeletonLoader = () => (
  <WidgetContainer>
    <SkeletonRect height='40px' width='70%' />
    <TutorsContainer>
      {[...Array(3)].map((_, i) => (
        <TutorItem key={i}>
          <SkeletonRect height='80px' width='100%' />
        </TutorItem>
      ))}
    </TutorsContainer>
  </WidgetContainer>
)

const WidgetContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: ${fadeInUp} 0.5s ease-out forwards;
  opacity: 0;

  &:nth-child(1) {
    animation-delay: 0.1s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }
  &:nth-child(4) {
    animation-delay: 0.4s;
  }
`

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`

const TaskItem = styled.div`
  width: 100%;
`

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  margin-top: 20px;
`

const TutorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`

const TutorItem = styled.div`
  width: 100%;
`
