import { createSelector, OutputSelector } from 'reselect'
import { propOr, pipe, all, ifElse, always, pathOr } from 'ramda'
import { isDateBeforeToday } from 'utils/date'
import { isNilOrEmpty } from 'utils/ramda'

export const selectCourses = (state: any) => state.courses

export const getCoursesList: OutputSelector<any, any, (res: any) => any> =
  createSelector(selectCourses, propOr([], 'courses'))

export const getCurrentCourse: OutputSelector<any, any, (res: any) => any> =
  createSelector(selectCourses, propOr({}, 'currentCourse'))

const isCourseExpired = pipe(propOr('', 'accessible_to'), isDateBeforeToday)

export const getHasAllCoursesExpired: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectCourses,
  pipe(
    propOr([], 'courses'),
    ifElse(isNilOrEmpty, always(false), all(isCourseExpired))
  )
)

export const getCourseFlashcardsCount: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectCourses,
  pipe(pathOr(0, ['currentCourse', 'flashcard_count']), Number)
)

export const getCurseBooksOrder: OutputSelector<any, any, (res: any) => any> =
  createSelector(
    selectCourses,
    pipe(pathOr('[]', ['currentCourse', 'book_order']), JSON.parse)
  )

export const getAiTutorEnabledInCourse: OutputSelector<
  any,
  any,
  (res: any) => any
> = createSelector(
  selectCourses,
  pipe(
    pathOr('true', ['currentCourse', 'original', 'ai_tutor_enabled']),
    JSON.parse
  )
)
