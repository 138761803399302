import { Action, BooksReducer } from 'types'
import * as R from 'ramda'
import { API_STATES } from 'utils/redux'
import { BOOK_API_RESPONSE_TO_REDUCER_MAP } from 'modules/books/utils/reducer'
import {
  fetchAllBooksRoutine,
  fetchChapterSectionsWithNotesRoutine,
  fetchChaptersWithExamsRoutine,
  fetchNotesForSubchapterRoutine,
  fetchTotalPartDataRoutine,
  resetBookDataRoutine,
  saveScrollAnchorPositionRoutine,
  saveBookContentPositionsRoutine,
  refreshBookFragmentDataRoutine,
  updateFlashcardManuallyRoutine,
  updateContentQuestionManuallyRoutine,
  fetchTotalPartDataStageRoutine,
  fetchBookContentsPartialRoutine,
  upsertBookContentHighlightsRoutine
} from 'modules/books/ducks/actions'
import { propOr } from 'ramda'
import { replaceItemInListById } from '../../../utils/array'
import {
  confirmCourseTopicRoutine,
  uncheckCourseTopicRoutine
} from '../../courseTopics/ducks/actions'

export const initialState: BooksReducer = {
  booksNavigation: [],
  currentBookChaptersWithExams: [],
  books: {
    state: API_STATES.PRISTINE,
    list: []
  },
  originalBooks: {
    state: API_STATES.PRISTINE,
    list: []
  },
  chapters: {
    state: API_STATES.PRISTINE,
    list: []
  },
  subchapters: {
    state: API_STATES.PRISTINE,
    list: []
  },
  bookContents: {
    state: API_STATES.PRISTINE,
    list: []
  },
  resources: {
    state: API_STATES.PRISTINE,
    list: []
  },
  attachments: {
    state: API_STATES.PRISTINE,
    list: []
  },
  contentQuestions: {
    state: API_STATES.PRISTINE,
    list: []
  },
  bookContentImages: {
    state: API_STATES.PRISTINE,
    list: []
  },
  bookChapterImages: {
    state: API_STATES.PRISTINE,
    list: []
  },
  contentFlashcards: {
    state: API_STATES.PRISTINE,
    list: []
  },
  topics: {
    state: API_STATES.PRISTINE,
    list: []
  },
  bookContentComments: {
    state: API_STATES.PRISTINE,
    list: []
  },
  notes: {},
  chapterSectionsWithNotes: [],
  scrollAnchorPositionY: 0,
  bookContentsPositions: []
}

const updateTopicIsMasteredValue = (state, data) => {
  const updatedTopic = data
  const topics = R.pathOr([], ['topics', 'list'], state)

  // @ts-ignore
  return topics.map(topic =>
    // @ts-ignore
    topic.student_course_topic_id === updatedTopic.id
      ? // @ts-ignore
        { ...topic, is_mastered: updatedTopic.is_mastered }
      : topic
  )
}

export default (state = initialState, action: Action): BooksReducer => {
  const fragmentName = R.pathOr('', ['payload', 'fragmentName'], action)
  const fragmentReducerName = BOOK_API_RESPONSE_TO_REDUCER_MAP[fragmentName]
  const payloadList = R.pathOr([], ['payload', 'data', fragmentName], action)
  // @ts-ignore
  const list = fragmentName === 'book' ? [payloadList] : payloadList

  switch (action.type) {
    case saveScrollAnchorPositionRoutine.SUCCESS:
      return {
        ...state,
        scrollAnchorPositionY: propOr(0, 'positionY', action.payload)
      }
    case saveBookContentPositionsRoutine.SUCCESS:
      return {
        ...state,
        bookContentsPositions: action.payload
      }
    case fetchAllBooksRoutine.SUCCESS:
      return {
        ...state,
        booksNavigation: R.pipe(
          R.pathOr([], ['payload', 'data']),
          R.filter(R.propEq('is_test_bundle', false))
        )(action)
      }
    case fetchTotalPartDataRoutine.REQUEST:
      return {
        ...state,
        books: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        originalBooks: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        chapters: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        subchapters: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        bookContents: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        resources: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        attachments: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        contentQuestions: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        bookContentImages: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        bookChapterImages: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        contentFlashcards: {
          state: API_STATES.IN_PROGRESS,
          list: []
        },
        topics: {
          state: API_STATES.IN_PROGRESS,
          list: []
        }
      }
    case fetchTotalPartDataRoutine.SUCCESS:
      return {
        ...state,
        books: {
          state: API_STATES.DONE,
          list: [
            R.pathOr(
              {
                title: '',
                id: '',
                course_id: '',
                original_book_id: '',
                tag: '',
                tag_colour: 'orange',
                book_id: ''
              },
              ['payload', 'book'],
              action
            )
          ]
        },
        originalBooks: {
          state: API_STATES.DONE,
          list: [
            R.pathOr(
              {
                id: '',
                flashcards_accessible: true,
                ai_tutor_enabled: true
              },
              ['payload', 'original_book'],
              action
            )
          ]
        },
        chapters: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'chapters'], action)
        },
        subchapters: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'subchapters'], action)
        },
        bookContents: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'book_contents'], action)
        },
        resources: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'resources'], action)
        },
        attachments: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'attachments'], action)
        },
        contentQuestions: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'content_questions'], action)
        },
        bookContentImages: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'content_images'], action)
        },
        bookChapterImages: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'chapter_images'], action)
        },
        contentFlashcards: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'flashcards'], action)
        },
        topics: {
          state: API_STATES.DONE,
          list: R.pathOr([], ['payload', 'topics'], action)
        }
      }
    case fetchTotalPartDataStageRoutine.REQUEST:
      return {
        ...state,
        // @ts-ignore
        [fragmentReducerName]: {
          state: API_STATES.IN_PROGRESS,
          list: []
        }
      }
    case refreshBookFragmentDataRoutine.SUCCESS:
    case fetchTotalPartDataStageRoutine.SUCCESS:
      return {
        ...state,
        // @ts-ignore
        [fragmentReducerName]: {
          state: API_STATES.DONE,
          list: list
        }
      }
    case fetchNotesForSubchapterRoutine.SUCCESS:
      return {
        ...state,
        notes: action.payload
      }
    case fetchChapterSectionsWithNotesRoutine.SUCCESS:
      return {
        ...state,
        chapterSectionsWithNotes: action.payload
      }
    case resetBookDataRoutine.SUCCESS:
      return {
        ...state,
        ...initialState
      }
    case fetchChaptersWithExamsRoutine.SUCCESS:
      return {
        ...state,
        currentBookChaptersWithExams: action.payload
      }
    case updateFlashcardManuallyRoutine.SUCCESS:
      return {
        ...state,
        contentFlashcards: {
          state: API_STATES.DONE,
          list: replaceItemInListById(action.payload)(
            state.contentFlashcards.list
          )
        }
      }
    case updateContentQuestionManuallyRoutine.SUCCESS:
      return {
        ...state,
        contentQuestions: {
          state: API_STATES.DONE,
          list: replaceItemInListById(action.payload)(
            state.contentQuestions.list
          )
        }
      }
    case confirmCourseTopicRoutine.SUCCESS:
    case uncheckCourseTopicRoutine.SUCCESS:
      return {
        ...state,
        topics: {
          ...state.topics,
          list: updateTopicIsMasteredValue(state, action.payload.data)
        }
      }
    case fetchBookContentsPartialRoutine.REQUEST:
      return {
        ...state,
        bookContents: {
          state: API_STATES.IN_PROGRESS,
          list: []
        }
      }
    case fetchBookContentsPartialRoutine.SUCCESS:
      return {
        ...state,
        bookContents: {
          state: API_STATES.DONE,
          list: [...action.payload.book_contents]
        }
      }
    case upsertBookContentHighlightsRoutine.SUCCESS:
      return {
        ...state,
        bookContents: {
          ...state.bookContents,
          list: state.bookContents.list.map(content =>
            content.id === action.payload.data.id
              ? {
                  ...content,
                  delta_object: action.payload.data.delta_object,
                  raw: action.payload.data.raw
                }
              : content
          )
        }
      }
    default:
      return state
  }
}
