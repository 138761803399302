import { ClockIcon } from 'examkrackers-components'
import styled, { css } from 'styled-components'

export const CalendarWidgetContainer = styled.div`
  h3 {
    font-weight: 900;
    font-family: 'Arial Black', Arial, sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin-bottom: 8px;
  }
`

export const WidgetTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.main.black};
`

export const SeeCalendarButton = styled.button`
  all: unset;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.text};
  text-decoration: underline;
  cursor: pointer;
  line-height: 25px;
`

export const Card = styled.div<{ $isDark?: boolean }>`
  position: relative;
  border-radius: 8px;
  padding: 8px;
  box-shadow: ${({ theme }) => theme.colors.dashboard.shadow};
  background-color: ${({ $isDark }) => ($isDark ? '#2C2C2C' : 'white')};
  height: 177px;
  overflow: visible;
`

export const CardHeader = styled.div<{ $isDark?: boolean }>`
  display: flex;
  align-items: center;
  /* margin-bottom: 16px; */
  justify-content: space-between;
  color: ${({ $isDark }) => ($isDark ? 'white' : 'inherit')};
`

export const Icon = styled.span`
  font-size: 24px;
  margin-right: 6px;
  color: ${({ theme }) => theme.colors.main.white};
  position: relative;
  top: -4px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`

export const Title = styled.h2<{ $isDark?: boolean }>`
  margin: 0;
  font-size: 16px;
  position: relative;
  font-weight: 700;
  top: -2px;
  color: ${({ $isDark }) => ($isDark ? 'white' : 'inherit')};
`

export const DaysContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* gap: 1px; */
  height: 121px;
  /* padding: 0 1px; */
  border-radius: 0;
  margin: 0 5px;
  position: relative;
`

export const DayColumn = styled.div<{
  $isDark?: boolean
  $isToday?: boolean
  $isStartDate?: boolean
  $isFinalDate?: boolean
  $isMcatDate?: boolean
}>`
  /* border-radius: 6px; */
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ $isMcatDate, theme }) =>
    $isMcatDate ? theme?.colors?.calendar.exam.orange.tag : 'transparent'};
  padding: 0 2px;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 121px;
    border: 1.5px solid ${({ theme }) => theme.colors.main.grey300};

    pointer-events: none;
    z-index: 99;
  }

  ${({ $isToday }) =>
    $isToday
      ? css`
          &::before {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 1px;
            border: 2px solid #444;
            border-radius: 6px;
            z-index: 100;
            pointer-events: none;
          }
        `
      : null}

  ${({ $isStartDate }) =>
    $isStartDate &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 1px;
        border: 2px solid #119b4c;
        border-radius: 6px;
        z-index: 100;
        pointer-events: none;
      }
    `}

  ${({ $isFinalDate }) =>
    $isFinalDate &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 1px;
        border: 2px solid #e83e2e;
        border-radius: 6px;
        z-index: 100;
        pointer-events: none;
      }
    `}

  ${({ $isMcatDate, theme }) =>
    $isMcatDate &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border: 2px solid
          ${({ theme }) => theme?.colors?.calendar.exam.orange.tag};
        border-radius: 6px;
        z-index: 100;
        pointer-events: none;
      }
    `}
`

export const NoEventsContainer = styled.div<{
  $isDark?: boolean
  $isDraggingOver?: boolean
  $isMcatDate?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ $isDark, $isMcatDate, theme }) =>
    $isMcatDate
      ? theme?.colors?.calendar.exam.orange.tag
      : $isDark
      ? '#2A2A2A'
      : theme.colors.main.primary200};
  border-radius: 4px;
  transition: background-color 0.2s ease;
  position: relative;
  margin-top: -1px;
  margin-left: 0;
  margin-right: -1px;

  ${({ $isDraggingOver }) =>
    $isDraggingOver &&
    css`
      & > * {
        pointer-events: none;
      }

      background-color: ${({ $isMcatDate, theme }) =>
        $isMcatDate
          ? theme?.colors?.calendar.exam.orange.tag
          : theme.colors.main.primary200};
    `}

  & > div[data-rbd-placeholder-context-id] {
    display: none;
  }

  ${({ $isMcatDate }) =>
    $isMcatDate &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.colors.main.white};
    `}
`

export const NoEventsMessage = styled.p<{ $isDark?: boolean }>`
  text-align: center;
  color: ${({ $isDark }) => ($isDark ? '#ccc' : 'inherit')};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const SetupCalendarButton = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

export const IconContainer = styled.div`
  border-radius: 4px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: black;
    font-size: 24px;
  }
`

export const DayHeader = styled.div<{
  $isDark?: boolean
  $isMcatDate?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 0 4px;
  height: auto;
  background: ${({ $isMcatDate, theme }) =>
    $isMcatDate ? theme?.colors?.calendar.exam.orange.tag : 'transparent'};
  margin: 0;
  border-radius: 4px 4px 0 0;

  span {
    font-size: 10px;
    color: ${({ $isMcatDate, theme }) =>
      $isMcatDate ? theme.colors.main.white : theme.colors.main.grey600};
  }
`

export const DayNumber = styled.div<{
  $today?: boolean
  $isStartDate?: boolean
  $isMcatDate?: boolean
  $holiday?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
  z-index: 13;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main.grey600};
  margin: 2px 0 4px 4px;

  ${({ $today }) =>
    $today &&
    css`
      background-color: ${({ theme }) => theme.colors.main.black};
      color: #fff;
      max-width: 19px;
      min-width: 19px;
      padding: 3px;
      /* min-height: 15px; */
      min-width: 19px;
      max-height: 19px;
      border-radius: 50%;
    `}

  ${({ $isStartDate }) =>
    $isStartDate &&
    css`
      background-color: #119b4c;
      color: #fff;
      max-width: 19px;
      padding: 3px;
      min-width: 19px;
      max-height: 19px;
      border-radius: 50%;
    `}

  ${({ $isMcatDate }) =>
    $isMcatDate &&
    css`
      background-color: ${({ theme }) =>
        theme?.colors?.calendar.exam.orange.tag};
      color: #fff;
      max-width: 19px;
      padding: 3px;
      min-width: 19px;
      max-height: 19px;
      border-radius: 50%;
    `}

  ${({ $holiday }) =>
    $holiday &&
    css`
      cursor: help;
      color: red;
    `}
`

export const PartySaltyImage = styled.img`
  position: absolute;
  top: -10px;
  right: 0;
  /* width: 120px; */
  z-index: 11;
`

export const StyledClockIcon = styled(ClockIcon)`
  font-size: 16px !important;
`
