import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import qs from 'qs'
import styled from 'styled-components'

import { AI_EVALUATION_PARAMS } from 'utils/aiEvaluation'
import AIEvaluationProgressBar from 'modules/AIChat/components/AIEvaluationProgressBar'

export const AIEvaluationAll = ({ percent }): JSX.Element => {
  const { t } = useTranslation()
  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const collapsed = propOr('false', AI_EVALUATION_PARAMS.collapse)(parsedQuery)

  const isCollapsed = collapsed === 'true'

  return (
    <Container>
      <DescriptionContainer isCollapsed={isCollapsed}>
        {t('aiEvaluation.all.description')}
      </DescriptionContainer>
      <BarContainer isCollapsed={isCollapsed}>
        <SectionTitle isCollapsed={isCollapsed}>
          {t('aiEvaluation.all.title')}
        </SectionTitle>
        <AIEvaluationProgressBar
          color='gold'
          percent={percent}
          title={t('aiEvaluation.all.label')}
          subtitle={isCollapsed ? t('aiEvaluation.all.labelDescription') : null}
        />
      </BarContainer>
    </Container>
  )
}

export default AIEvaluationAll

const SectionTitle = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  top: 8px;
  right: ${({ isCollapsed }) => (isCollapsed ? '14%' : '9%')};
  font-family: Arial Black;
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

const DescriptionContainer = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  padding: 22px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ isCollapsed }) => (isCollapsed ? '60%' : '70%')};
  height: 100%;
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 11.5px;
  text-align: left;
  background-color: ${props => props.theme.colors.backgrounds.main};
`

const BarContainer = styled.div<{ isCollapsed: boolean }>`
  margin-right: ${({ isCollapsed }) => (isCollapsed ? '12%' : '5%')};
  margin-bottom: -3px;

  #bar-title {
    min-width: ${({ isCollapsed }) => (isCollapsed ? '150px' : '100px')};
  }
`
