import React from 'react'
import styled from 'styled-components'

import AIChatMessageWrapper from './AIChatMessageWrapper'
import AICopyMessage from './AICopyMessage'

const AIChatMessageTutor: React.FC<{
  message: { role: string; contents: string }
}> = ({ message }) => {
  return (
    <AIChatMessageTutorContainer>
      <TutorIcon src='/assets/illustrations/AITutorIcon.svg' />
      <TutorMessage>
        <AIChatMessageWrapper message={message.contents} />
        <AICopyMessage message={message} />
      </TutorMessage>
    </AIChatMessageTutorContainer>
  )
}

export default AIChatMessageTutor

const AIChatMessageTutorContainer = styled.div`
  display: inline-flex;
  gap: 5px;
  padding: 8px;
`

const TutorIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-top: 4px;
  border-radius: 50%;
  background-color: orange;
`

const TutorMessage = styled.div`
  padding: 0 3px;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;

  p {
    margin: 0 0 8px 0;
  }
`
