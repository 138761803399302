import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'

import { saveBookReadingProgress } from 'services/BooksService'
import { useSelector } from 'react-redux'
import {
  getIsOrdinaryStudent,
  isAuthLoaded
} from 'modules/auth/ducks/selectors'
import qs from 'qs'

export const BookReadingProgress = (): JSX.Element => {
  const params = useParams()
  const parsedQuery = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const previousSubchapterRef = useRef<string | null>(null)
  const isMountedRef = useRef(true)

  const bookId: string = R.propOr('', 'bookId', params)
  const partOrder: string = R.propOr('', 'partOrder', params)
  const chapterOrder: string = R.propOr('', 'chapterOrder', params)
  const studentSubchapterId = (parsedQuery.sectionIdContext as string) || ''

  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)

  const saveBookProgress = () => {
    if (!isMountedRef.current) return

    const handleSuccess = () => {}
    const handleError = e => console.error(e)

    saveBookReadingProgress({
      bookId,
      partOrder,
      chapterOrder,
      studentSubchapterId
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  // Cleanup on unmount
  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  // Effect for immediate save on general params change
  useEffect(() => {
    if (!isMountedRef.current) return
    if (isOrdinaryStudent && isAuthFetched) {
      saveBookProgress()
    }
  }, [bookId, partOrder, chapterOrder, isOrdinaryStudent, isAuthFetched])

  // Effect for delayed save on subchapter change
  useEffect(() => {
    if (!isMountedRef.current) return
    if (!isOrdinaryStudent || !isAuthFetched) return

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    if (studentSubchapterId !== previousSubchapterRef.current) {
      previousSubchapterRef.current = studentSubchapterId

      timeoutRef.current = setTimeout(() => {
        if (isMountedRef.current) {
          saveBookProgress()
        }
      }, 10000)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [studentSubchapterId, isOrdinaryStudent, isAuthFetched])

  return <div />
}

export default BookReadingProgress
