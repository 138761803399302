import { TTutorClass } from 'types/tutoring'
import { createUTCFromEST } from 'utils/date'

export const getUpcomingClasses = (classesArray: TTutorClass[]) => {
  const now = new Date()

  const upcomingClasses = classesArray.filter(classItem => {
    const classEndTime = createUTCFromEST(
      classItem.class_date,
      classItem.class_time_end
    )

    return classEndTime > now
  })

  const sortedClasses = upcomingClasses.sort((a, b) => {
    const dateA = new Date(a.class_date)
    const dateB = new Date(b.class_date)

    if (dateA.getTime() !== dateB.getTime()) {
      return dateA.getTime() - dateB.getTime()
    }

    return a.class_time.localeCompare(b.class_time)
  })

  return sortedClasses.slice(0, 3)
}
