import { createRoutine } from 'redux-saga-routines'
import * as Effects from '@redux-saga/core/effects'
import * as SettingsService from 'services/SettingsService'
import { SETTINGS_NAMESPACE } from 'utils/settings'

// ROUTINES
export const fetchCourseSettingsRoutine = createRoutine('FETCH_COURSE_SETTINGS')
export const fetchSaltyBucksSettingsRoutine = createRoutine(
  'FETCH_SALTY_BUCKS_SETTINGS'
)

// ACTIONS
function* fetchCourseSettings() {
  yield Effects.put(fetchCourseSettingsRoutine.request())
  try {
    const result = yield Effects.call(
      SettingsService.fetchSettingsByNamespace,
      { namespace: SETTINGS_NAMESPACE.courses }
    )
    yield Effects.put(fetchCourseSettingsRoutine.success(result.data))
  } catch (e) {
    yield Effects.put(fetchCourseSettingsRoutine.failure(e))
  }
}

function* fetchSaltyBucksSettings() {
  yield Effects.put(fetchSaltyBucksSettingsRoutine.request())
  try {
    const result = yield Effects.call(
      SettingsService.fetchSettingsByNamespace,
      { namespace: SETTINGS_NAMESPACE.saltyBucks }
    )
    yield Effects.put(fetchSaltyBucksSettingsRoutine.success(result.data))
  } catch (e) {
    yield Effects.put(fetchSaltyBucksSettingsRoutine.failure(e))
  }
}

// WATCHERS
export function* fetchCourseSettingsWatcher() {
  yield Effects.takeLatest(
    fetchCourseSettingsRoutine.TRIGGER,
    fetchCourseSettings
  )
}

export function* fetchSaltyBucksSettingsWatcher() {
  yield Effects.takeLatest(
    fetchSaltyBucksSettingsRoutine.TRIGGER,
    fetchSaltyBucksSettings
  )
}

// SAGAS
export const settingsSagas = [
  Effects.fork(fetchCourseSettingsWatcher),
  Effects.fork(fetchSaltyBucksSettingsWatcher)
]
