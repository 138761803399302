import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CopyChatMessageIcon, Tooltip } from 'examkrackers-components'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/AIChat/utils/events'

const AICopyMessage: React.FC<{
  message: { role: string; contents: string }
}> = ({ message }) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleCopyMessage = () => {
    navigator.clipboard.writeText(message.contents)
    showToast({
      key: 'toast.messageCopied',
      severity: SEVERITY.success
    })
  }

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const setVisibility = () => setIsVisible(true)

  React.useEffect(() => {
    eventEmitter.on(events.aiChatTutorMessageSent, setVisibility)

    return () => {
      eventEmitter.off(events.aiChatTutorMessageSent, setVisibility)
    }
  }, [])

  if (!isVisible) return null

  return (
    <AICopyMessageContainer>
      <Tooltip
        tooltipContent={t('bookViewer.aiChat.copyMessage')}
        id='copy-message'
      >
        <CopyChatMessageIcon onClick={handleCopyMessage} />
      </Tooltip>
    </AICopyMessageContainer>
  )
}

export default AICopyMessage

const AICopyMessageContainer = styled.div`
  display: inline-block;
  max-width: 20px;

  svg {
    cursor: pointer;
    transition: color 0.3s ease;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.main.primary500};
  }
`
