import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from 'styled-components'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/diagnostics/utils/events'

import TimeChartExamSectionDiagnostics from 'modules/diagnostics/components/TimeChartExamSectionDiagnostics'
import TopTimeChartExamSectionDiagnostics from 'modules/diagnostics/components/TopTimeChartExamSectionDiagnostics'
import DifficultyChartExamSectionDiagnostics from 'modules/diagnostics/components/DifficultyChartExamSectionDiagnostics'
import LegendExamSectionDiagnostics from 'modules/diagnostics/components/LegendExamSectionDiagnostics'

import { getQuestionsData } from '../ducks/selectors'
import { BouncingLoader } from 'examkrackers-components'

export const TabExamSectionDiagnostics = ({ children }): JSX.Element => {
  const params = useParams()
  const { push } = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3500)
  }, [])

  const id: string = R.propOr('', 'id')(params)

  const questionsData = useSelector(getQuestionsData)

  const handleQuestionClick = questionNumber => {
    const questionId = R.pipe(
      R.find(R.propEq('order', questionNumber)),
      R.propOr('', 'id')
    )(questionsData)
    const explanationPath = `/exam/${id}/explanations/${questionId}`

    return push(explanationPath)
  }

  React.useEffect(() => {
    eventEmitter.on(events.questionClick, handleQuestionClick)

    return () => {
      eventEmitter.off(events.questionClick, handleQuestionClick)
    }
  }, [questionsData])

  if (loading) {
    return (
      <PageLoadingContainer loading={loading}>
        <BouncingLoader />
      </PageLoadingContainer>
    )
  }

  return (
    <Container loading={loading}>
      <LoadingContainer loading={loading}>
        <BouncingLoader />
      </LoadingContainer>
      <ChartWithTilesContainer>
        <Tiles>
          <TilesWrapper>{children}</TilesWrapper>
        </Tiles>
        <ChartContainer loading={loading}>
          <TopTimeChartExamSectionDiagnostics />
          <TimeChartExamSectionDiagnostics />
          <DifficultyChartExamSectionDiagnostics />
        </ChartContainer>
      </ChartWithTilesContainer>
      <LegendContainer loading={loading}>
        <LegendExamSectionDiagnostics />
      </LegendContainer>
    </Container>
  )
}

export default TabExamSectionDiagnostics

const Container = styled.div`
  max-height: 584px;
  height: calc(100vh - 140px);
  display: flex;
  gap: 16px;
  position: relative;
  overflow: ${({ loading }) => (loading ? 'hidden' : 'unset')};
`

const PageLoadingContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.menu};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  animation: timed-out-opacity 3500ms ease-in;
  display: ${({ loading }) => (loading ? 'flex' : 'none')};

  @keyframes timed-out-opacity {
    0% {
      opacity: 1;
      display: flex;
    }
    70% {
      opacity: 1;
      display: flex;
    }
    99% {
      opacity: 0;
      display: flex;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`
const ChartWithTilesContainer = styled.div`
  display: grid;
  grid-template-columns: 148px 1fr;
  width: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
`
const ChartContainer = styled.div`
  position: relative;
  /* background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px; */
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  * {
    font-family: ${({ theme }) => theme.typography.fontFamily} !important;
  }

  .apexcharts-gridlines-vertical {
    line {
      visibility: hidden;
    }
  }

  line.apexcharts-gridline.grid-hover-active {
    visibility: visible !important;
    stroke-dasharray: 0;
    stroke-width: 3px;
    stroke-opacity: 1;
    stroke: ${({ theme }) => theme.colors.main.text};
    z-index: 100;
  }

  .apexcharts-tooltip {
    border: none !important;
    box-shadow: none;
    border-radius: unset;
    background: transparent !important;
    overflow: visible !important;
  }

  .tooltip-question-view {
    background: ${({ theme }) => theme.colors.main.text};
    color: ${({ theme }) => theme.colors.main.white};
    padding: 4px 8px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 11px;
    border-radius: 4px;
    position: relative;
  }

  .apexcharts-xcrosshairs {
    display: none;
  }

  .apexcharts-marker {
    visibility: hidden;
  }

  .apexcharts-xaxis {
    stroke-opacity: 0;
  }

  .apexcharts-gridlines-horizontal .apexcharts-gridline,
  .apexcharts-gridlines-horizontal .apexcharts-gridline {
    stroke-opacity: 0;
  }

  .apexcharts-gridlines-horizontal .apexcharts-gridline:first-child,
  .apexcharts-gridlines-horizontal .apexcharts-gridline:last-child {
    stroke-opacity: 1;
  }

  .apexcharts-xaxis-label {
    visibility: hidden;
  }
`

const LegendContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  height: 100%;
  width: 288px;
  flex: none;
`
const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 16px 16px;
  /* align-items: center; */
  /* background-color: ${({ theme }) => theme.colors.backgrounds.main}; */
  /* box-shadow: ${({ theme }) => theme.shadows.mainShadow}; */
  height: 100%;
  gap: 15px;
`

const TilesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  gap: 16px;
  padding: 8px;
`
