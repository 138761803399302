import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import PATHS from 'utils/paths'

import Footer from 'components/Footer'
import AuthNav from 'components/AuthNav'
import { BouncingLoader } from 'examkrackers-components'

import ModalNarrowScreen from 'modules/student/components/ModalNarrowScreen'
import SupportTab from './SupportTab'
import Erratas from 'modules/supportTab/Erratas'
import { getStudent } from 'modules/auth/ducks/selectors'
import { useSelector } from 'react-redux'

export const LayoutAuth = ({ children }): JSX.Element => {
  const { path } = useRouteMatch()
  const withFooter = path !== PATHS.book
  const [isLoading, setIsLoading] = useState(true)
  const [erratasOpen, setErratasOpen] = useState<boolean>(false)
  const user = useSelector(getStudent)
  const isDashboard = path === PATHS.dashboard
  const isNotes = path === PATHS.notes
  const isBookNotes = path === PATHS.bookNotes
  const isBook = path === PATHS.book
  const isSelectCourse = path === PATHS.selectCourse

  const isWithoutLoader = isDashboard || isNotes || isBookNotes || isBook
  const isNotesPage = isNotes || isBookNotes

  useEffect(() => {
    if (!isNotesPage && !isBook) {
      setIsLoading(true)
      setTimeout(() => setIsLoading(false), 1500)
    } else {
      setIsLoading(false)
    }
  }, [path])

  return (
    <>
      <ModalNarrowScreen />
      <AuthNav />
      {!isWithoutLoader && (
        <LoadingContainer isLoading={isLoading}>
          <BouncingLoader />
        </LoadingContainer>
      )}
      <Body isLoading={isLoading} withFooter={withFooter}>
        <Content noPadding={path === PATHS.book}>
          {children}
          {erratasOpen ? (
            <Erratas
              erratasOpen={erratasOpen}
              setErratasOpen={setErratasOpen}
            />
          ) : null}
        </Content>
      </Body>
      {withFooter && <Footer />}
      {isSelectCourse ? null : user.has_courses ? (
        <SupportTab setErratasOpen={setErratasOpen} />
      ) : null}
    </>
  )
}

interface BodyProps {
  isLoading: boolean
  withFooter: boolean | undefined
}

const Body = styled.div<BodyProps>`
  display: flex;
  margin-top: ${({ theme }) => theme.dimensions.studentTopNavHeight};
  height: ${({ withFooter, theme }) =>
    withFooter
      ? `calc(100vh - ${theme.dimensions.studentTopNavHeight} - ${theme.dimensions.footerHeight})`
      : `calc(100vh - ${theme.dimensions.studentTopNavHeight})`};
  opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
  transition: all 1000ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

interface ContentProps {
  noPadding: boolean
}

const Content = styled.div<ContentProps>`
  position: relative;
  flex-grow: 1;
  height: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 10px;
  // Commented for tweak in BookViewer tsx (LeftContainerInner)
  // overflow: auto;

  th {
    top: -40px;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        top: -72px;
      }
    }
  }
`

interface LoadingContainerProps {
  isLoading: boolean
  withFooter?: boolean | undefined
}

const LoadingContainer = styled.div<LoadingContainerProps>`
  position: absolute;
  left: 0;
  margin-top: ${({ theme }) => theme.dimensions.studentTopNavHeight};
  width: 100%;
  height: ${({ withFooter, theme }) =>
    withFooter
      ? `calc(100vh - ${theme.dimensions.studentTopNavHeight} - ${theme.dimensions.footerHeight})`
      : `calc(100vh - ${theme.dimensions.studentTopNavHeight})`};
  z-index: ${({ theme }) => theme.zIndex.menu};
  background: ${({ theme }) => theme.colors.backgrounds.app};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  animation: timed-out-opacity 2000ms ease-in;
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};

  @keyframes timed-out-opacity {
    0% {
      opacity: 1;
      display: flex;
    }
    70% {
      opacity: 1;
      display: flex;
    }
    99% {
      opacity: 0;
      display: flex;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`

export default LayoutAuth
