import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

interface NumberAnimatorProps {
  value: number
  digits?: number
}

const NumberAnimator: React.FC<NumberAnimatorProps> = ({
  value,
  digits = 6
}) => {
  const [oldValue, setOldValue] = useState<(number | string)[]>([])
  const containerRef = useRef<HTMLDivElement>(null)

  const createNumberArray = (number: number): (number | string)[] => {
    if (number === 0) {
      return [0]
    }
    const isNegative = number < 0
    return [
      ...(isNegative ? ['-'] : []),
      ...Math.abs(number)
        .toString()
        .split('')
        .map(x => parseInt(x))
    ]
  }

  const calcDeltaSight = (
    oldSight: string | number,
    newSight: string | number
  ) => {
    return oldSight !== newSight
      ? [`<span>${oldSight}</span>`, `<span>${newSight}</span>`]
      : [`<span>${newSight}</span>`]
  }

  const calcDeltaBetweenNumbers = (oldNumber: number, newNumber: number) => {
    const numberArray = [oldNumber]
    let current = oldNumber

    if (oldNumber === newNumber) {
      return numberArray.map(x => `<span>${x}</span>`)
    }

    while (current !== newNumber) {
      current++
      if (current > 9) current = 0
      numberArray.push(current)
      if (current === newNumber) break
    }

    return numberArray.map(x => `<span>${x}</span>`)
  }

  const createNumberHTML = (
    numbers: (number | string)[],
    old: (number | string)[]
  ) => {
    if (!containerRef.current) return

    containerRef.current.innerHTML = ''

    numbers.forEach((num, i) => {
      const span = document.createElement('span')

      if (isNaN(Number(numbers[i])) || isNaN(Number(old[i]))) {
        const delta = calcDeltaSight(old[i], numbers[i])
        span.setAttribute('data-value', delta.length.toString())
        span.innerHTML = delta.join('')
      } else {
        const delta = calcDeltaBetweenNumbers(
          Number(old[i]),
          Number(numbers[i])
        )
        span.setAttribute('data-value', delta.length.toString())
        span.innerHTML = delta.join('')
      }

      containerRef.current?.appendChild(span)
    })

    setTimeout(() => {
      const ticks = containerRef.current?.querySelectorAll('span[data-value]')
      ticks?.forEach(tick => {
        const dist = parseInt(tick.getAttribute('data-value') || '1') - 1
        ;(tick as HTMLElement).style.transform = `translateY(-${dist * 100}%)`
      })
    }, 0)
  }

  useEffect(() => {
    const numbers = createNumberArray(value)
    if (oldValue.length === 0) {
      setOldValue(numbers)
      createNumberHTML(numbers, numbers)
    } else {
      createNumberHTML(numbers, oldValue)
      setOldValue(numbers)
    }
  }, [value])

  return <NumberContainer ref={containerRef} />
}

export default NumberAnimator

const NumberContainer = styled.div`
  display: flex;
  overflow: hidden;

  > span {
    height: 18.4px;
    width: 1ch;
    text-align: center;
    transition: transform 0.5s ease-in-out;

    > span {
      display: block;
      height: 100%;
    }
  }
`
