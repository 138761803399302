import React, { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import TasksWidget from './TasksWidget'
import { CalendarContext } from 'hooks/CalendarContext'

import styled from 'styled-components'
import { StudentTask, Task } from 'types/dashboard'
import { useHistory } from 'react-router-dom'
import paths from 'utils/paths'
import { getTasks } from 'services/TaskService'
import TasksWidgetService from './TasksWidgetService'
import { isNotNilOrEmpty } from 'utils/ramda'

import * as R from 'ramda'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'
import { COURSE_TYPES } from 'utils/courses'

const NextTasks: React.FC = () => {
  const history = useHistory()

  const { lists, isLoading, calendar } = useContext(CalendarContext)
  const [tasks, setTasks] = useState<(StudentTask | Task)[]>([])
  const hasStudentCalendar = isNotNilOrEmpty(calendar)

  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrialCourse = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const handleSeeCalendarClick = () => {
    if (hasStudentCalendar) {
      history.push(paths.calendar)
    } else if (isFreeTrialCourse) {
      history.push(paths.calendarSetupFreeTrial)
    } else {
      history.push(paths.calendarSetup)
    }
  }

  const getBeginnerTasks = React.useCallback(async () => {
    try {
      const res = await getTasks()

      const upcomingTasks = TasksWidgetService.getIncompleteTasks(lists)
      const beginnerTasks = TasksWidgetService.filterBeginnerTasks(
        res.data,
        hasStudentCalendar
      )

      const handleEmptyState = TasksWidgetService.addEmptyTasks([
        ...beginnerTasks,
        ...upcomingTasks
      ])

      setTasks(handleEmptyState)
    } catch (error) {
      console.error('Error fetching tasks:', error)
    }
  }, [lists])

  useEffect(() => {
    if (!isLoading) {
      getBeginnerTasks()
    }
  }, [isLoading, getBeginnerTasks, lists])

  return (
    <Container>
      {tasks && tasks.length > 0 ? (
        <TasksWidget
          tasks={tasks}
          onSeeCalendarClick={handleSeeCalendarClick}
          key={JSON.stringify(lists)}
        />
      ) : null}
    </Container>
  )
}

export default NextTasks

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 414px;
  width: 509px;
  gap: 20px;
  color: ${({ theme }) => theme.colors.main.black};
`
