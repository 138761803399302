import React, { useRef, useState, useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  CalendarIcon,
  Button,
  SingleSelect,
  BouncingLoader
} from 'examkrackers-components'
import RemoveCalendarEventModal from 'modules/calendar/RemoveCalendarEventModal'
import { calendarEventType, statusOptions } from 'utils/calendar'
import {
  formatMinutesToHoursAndMinutes,
  getUniversalDateString
} from 'utils/date'
import { useTranslation } from 'react-i18next'
import { getOptionByValue } from 'utils/form'
import useOutsideClickWithoutTimeout from 'hooks/useOutsideClickWithoutTimeout'
import {
  updateCalendarEvent,
  getSingleCalendarEvent
} from 'services/CalendarService'
import AddOrEditEventModal from 'modules/calendar/AddOrEditEventModal'
import { useHistory } from 'react-router-dom'
import DateInput from 'components/DateInput/DateInput'
import { CalendarContext } from 'hooks/CalendarContext'
import { equals } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

interface DisplayEventModalProps {
  open
  setOpen
  setUpdateStatusEvent: (value: string) => void
  hideMenu: () => void
  updateStatusEvent: any
  containerPosition: any
  event: any
}

const DisplayCustomEventModal: React.FC<DisplayEventModalProps> = ({
  open,
  setOpen,
  setUpdateStatusEvent,
  updateStatusEvent,
  hideMenu,
  containerPosition,
  event
}) => {
  const { updateEvent, courseEndDate } = useContext(CalendarContext)

  const {
    action_uri,
    duration,
    event_colour,
    event_date,
    id,
    title,
    description,
    type,
    status
  } = event

  const defaultOption = getOptionByValue(updateStatusEvent)(statusOptions())
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [eventDate, setEventDate] = useState({ event_date: event_date })
  const modalRef = useRef<HTMLDivElement>(null!)
  const [_, setClose] = useState(false)
  const { t } = useTranslation()
  const { push } = useHistory()

  const hasDescription = description && description.length > 0

  const top = containerPosition?.top || 0
  const left = containerPosition?.left || 0
  const color = event_colour

  const isCustomEndDateEvent = type === calendarEventType.customEndDateEvent

  const handleEventUpdate = async selectedOption => {
    setIsLoading(true)
    try {
      const response = await updateCalendarEvent({
        id,
        status: selectedOption.value,
        event_date: eventDate.event_date
      })

      if ([200, 201].includes(response.status)) {
        updateEvent({ oldEvent: event, newEvent: response.data })
        setUpdateStatusEvent(response.data.status)
      }
      setIsLoading(false)
      getSingleCalendarEvent({ id: id as string })
    } catch (error) {
      console.error('error', error)
    }
    setIsLoading(false)
  }

  const handleClose = async () => {
    await setClose(true)
    setOpen(false)
  }

  useOutsideClickWithoutTimeout(modalRef, handleClose)

  const handleChange = (e: any) => {
    setSelectedOption(e)
  }

  const handleSave = async () => {
    await handleEventUpdate(selectedOption)
    setUpdateStatusEvent(selectedOption.value)
    handleClose()
  }

  const redirectToSelectItem = () => {
    push(action_uri)
  }

  const handleValueChange = (name, value) => {
    setEventDate(prev => ({ ...prev, [name]: value }))
  }

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  const hasChanges =
    !equals(selectedOption, defaultOption) ||
    !equals(event_date, eventDate.event_date)

  useEffect(() => {
    setSelectedOption(getOptionByValue(status)(statusOptions()))
  }, [status])

  useEffect(() => {
    setEventDate({ event_date: event_date })
  }, [event])

  return open ? (
    <EventCard
      open={open}
      top={top}
      left={left}
      ref={modalRef}
      data-testid='DisplayCustomEventModal-EventCard'
    >
      <Container data-testid='DisplayCustomEventModal-Container'>
        <StatusWrapper data-testid='DisplayCustomEventModal-StatusWrapper'>
          <DateWrapper data-testid='DisplayCustomEventModal-DateWrapper'>
            <div>
              {t('calendar.scheduled')}:{' '}
              <DateInputWrapper data-testid='DisplayCustomEventModal-DateInputWrapper'>
                <PickerWrapper data-testid='DisplayCustomEventModal-PickerWrapper'>
                  <DateInput
                    data-testid='DisplayCustomEventModal-DateInput'
                    name='event_date'
                    popperPlacement='right-start'
                    id={`custom_event_event_date-${id}`}
                    value={getUniversalDateString(eventDate.event_date)}
                    validate={() => true}
                    size='small'
                    onChange={handleValueChange}
                    minDate={new Date()}
                    maxDate={courseEndDate}
                  />
                </PickerWrapper>
              </DateInputWrapper>
            </div>
            <IconContainer data-testid='DisplayCustomEventModal-IconContainer'>
              <CalendarIcon
                data-testid='DisplayCustomEventModal-CalendarIcon'
                onClick={handleCalendarManualOpen(
                  `custom_event_event_date-${id}`
                )}
              />
            </IconContainer>
          </DateWrapper>
          <ActionWrapper data-testid='DisplayCustomEventModal-ActionWrapper'>
            {type === 'custom' && (
              <AddOrEditEventModal
                data-testid='DisplayCustomEventModal-AddOrEditEventModal'
                setUpdateStatusEvent={setUpdateStatusEvent}
                event={event}
                onSelect={hideMenu}
              />
            )}
            {!isCustomEndDateEvent && (
              <RemoveCalendarEventModal
                event={event}
                onSelect={hideMenu}
                data-testid='DisplayCustomEventModal-RemoveCalendarEventModal'
              />
            )}
          </ActionWrapper>
        </StatusWrapper>
        <DurationWrapper data-testid='DisplayCustomEventModal-DurationWrapper'>
          {t('calendar.duration')}: {formatMinutesToHoursAndMinutes(duration)}
        </DurationWrapper>
        <BorderContainer data-testid='DisplayCustomEventModal-BorderContainer' />
        <TopWrapper data-testid='DisplayCustomEventModal-TopWrapper'>
          <div>{t('calendar.addCustomEventModal.eventTitle')}:</div>
          <TitleWrapper
            color={color}
            data-testid='DisplayCustomEventModal-TitleWrapper'
          >
            {title}
          </TitleWrapper>
        </TopWrapper>
        {hasDescription && (
          <>
            <div>{t('calendar.addCustomEventModal.description')}:</div>
            <DescriptionWrapper
              color={color}
              data-testid='DisplayCustomEventModal-DescriptionWrapper-description'
            >
              {description}
            </DescriptionWrapper>
          </>
        )}
        <BottomWrapper data-testid='DisplayCustomEventModal-BottomWrapper'>
          <SelectWrapper data-testid='DisplayCustomEventModal-SelectWrapper'>
            <SingleSelect
              data-testid='DisplayCustomEventModal-SingleSelect'
              removeMargin
              id={`${id}-select-status`}
              label={t('calendar.eventCard.status')}
              size='small'
              options={statusOptions(['archived'])}
              onChange={handleChange}
              value={selectedOption}
              isSearchable={false}
            />
          </SelectWrapper>
        </BottomWrapper>
        {isLoading ? (
          <LoaderContainer data-testid='DisplayCustomEventModal-LoaderContainer'>
            <BouncingLoader data-testid='DisplayCustomEventModal-BouncingLoader' />
          </LoaderContainer>
        ) : (
          <ButtonsWrapper data-testid='DisplayCustomEventModal-ButtonsWrapper'>
            <Button
              data-testid='DisplayCustomEventModal-Button-cancel'
              variant='contained'
              color='secondary'
              size='small'
              onClick={handleClose}
            >
              {t('calendar.button.cancel')}
            </Button>
            <Button
              data-testid='DisplayCustomEventModal-Button-save'
              variant='contained'
              color='primary'
              size='small'
              onClick={handleSave}
              disabled={!hasChanges}
            >
              {t('calendar.button.save')}
            </Button>
            {type === 'custom_full_length_exam_review' ||
            type === 'custom_section_exam_review' ? (
              <Button
                data-testid='DisplayCustomEventModal-Button-begin'
                variant='contained'
                color='tertiary'
                size='small'
                onClick={redirectToSelectItem}
              >
                {t('calendar.button.begin')}
              </Button>
            ) : null}
          </ButtonsWrapper>
        )}
      </Container>
      <ArrowIconWrapper
        data-testid='DisplayCustomEventModal-ArrowIconWrapper'
        top={top}
      />
    </EventCard>
  ) : null
}

export default DisplayCustomEventModal

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`
const ArrowIconWrapper = styled.div`
  position: absolute;

  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 0;
  border-top: 15px solid white;
  ${({ top }) =>
    top < 300
      ? css`
          top: -24px;
          transform: rotate(180deg);
        `
      : 'bottom: -24px;'}
`

const TitleWrapper = styled.div<{
  color: string
}>`
  font-weight: bold;
  font-size: 14px;
  padding: 6px;

  color: ${({ theme }) =>
    theme?.colors?.calendar.custom.brown.border || theme.colors.main.black};
`
const DescriptionWrapper = styled.div`
  padding: 6px;
  color: ${({ isSectionExamTypeEvent, isFullLengthExamEvent, color, theme }) =>
    isFullLengthExamEvent || isSectionExamTypeEvent
      ? theme.colors.calendar.exam.orange.tag
      : theme.colors.calendar[color]?.tag || theme.colors.main.black};
`

const EventCard = styled.div<{ open: boolean; left: number; top: number }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  // TODO: calculate properly not on numbers from thin air
  left: ${({ left }) => left - 105}px;
  top: ${({ top }) => (top < 300 ? top + 24 : top - 230)}px;
  width: 340px;
  color: ${({ theme }) => theme.colors.main.grey600};
  z-index: 999;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
`
const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary400};
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 90px;
`
const BorderContainer = styled.div`
  margin: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
`
const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 220px;
`
const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 8px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 8px;
`
const ActionWrapper = styled.div`
  display: flex;
`
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const PickerWrapper = styled.div`
  display: flex;
  width: 95px;
  position: absolute;
  left: 85px;
  top: -30px;
`
const DateInputWrapper = styled.div`
  width: 95px;
`
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 52px;
`
const DurationWrapper = styled.div`
  margin-top: 10px;
`
