import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { pathOr, propOr } from 'ramda'
import qs from 'qs'
import styled from 'styled-components'
import { ArrowDownIcon } from 'examkrackers-components'

import { AI_EVALUATION_PARAMS } from 'utils/aiEvaluation'

import AIChat from './AIChat'
import AIEvaluationBookChapterFilter from './AIEvaluationBookChapterFilter'

export const AIEvaluationChatCollapse = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    replace,
    location: { pathname, search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const collapsed = propOr('false', AI_EVALUATION_PARAMS.collapse)(parsedQuery)
  const selectedChapter = pathOr(
    '',
    [AI_EVALUATION_PARAMS.chapterId],
    parsedQuery
  )

  const handleCollapseClick = () => {
    const newQuery = {
      ...parsedQuery,
      collapse: collapsed === 'true' ? 'false' : 'true'
    }

    replace(`${pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
  }

  return (
    <Container isCollapsed={collapsed === 'true'}>
      <CollapseCTA onClick={handleCollapseClick}>
        <CollapseText>{t('aiEvaluation.chatCollapse.title')}</CollapseText>
        <ArrowDownIcon />
      </CollapseCTA>
      <AIEvaluationBookChapterFilter />
      <ChatContainer isCollapsed={collapsed === 'true'}>
        <AIChat isEvaluation chatContext={selectedChapter} />
      </ChatContainer>
    </Container>
  )
}

export default AIEvaluationChatCollapse

const Container = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: ${({ isCollapsed }) => (isCollapsed ? '35px' : '100%')};
  border: 0.5px solid #c5c6c8;
  box-shadow: 2px 2px 6px 0px #00000033 inset;
  background: ${props => props.theme.colors.backgrounds.main};
  border-radius: ${({ theme }) => theme.shape.borderRadiusBig};
  position: absolute;
  right: 8px;
  top: 16px;
  z-index: 1000;
  transition: all 300ms ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;

  svg {
    transform: ${({ isCollapsed }) =>
      isCollapsed ? 'rotate(180deg)' : 'rotate(0)'};
    transition: transform 300ms
      ${({ theme }) => theme.transitions.easing.easeInOut};
  }

  #ai-evaluation-book-chapter-filters {
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'flex')};
  }
`

const CollapseCTA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 35px;

  svg {
    color: ${({ theme }) => theme.colors.main.primary500};
    font-size: 22px;
  }
`

const CollapseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

const ChatContainer = styled.div<{ isCollapsed: boolean }>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  height: calc(100% - 67px);
  flex-grow: 1;
  width: 100%;
  opacity: 0;
  animation: fadeIn 1000ms ${({ theme }) => theme.transitions.easing.easeInOut}
    forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
