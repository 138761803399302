export const SETTINGS_NAMESPACE = {
  courses: 'courses',
  saltyBucks: 'salty_bucks'
}

export const COURSE_SETTINGS = {
  autoPauseDelay: 'auto-pause-delay'
}

export const SALTY_BUCKS_SETTINGS = {
  aiChatPromptCost: 'ai_chat_prompt_cost'
} as const
